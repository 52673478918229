import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import AppLayout from './AppLayout';
import Login from './components/Login';
import { useProvided } from './store/index';
import useAuth from './store/useAuth';
//
import 'antd/dist/antd.css';
//import './App.css';

const App = () => {
  const { user } = useProvided(useAuth);

  const PrivateRoute = ({ component, ...rest }) => {
    return (
      <Route
        {...rest}
        render={(props) => (user ? React.createElement(component, props) : <Redirect to="/login" />)}
      />
    );
  };

  const PublicRoute = ({ component, ...rest }) => {
    return (
      <Route
        {...rest}
        render={(props) => (user ? <Redirect to="/home" /> : React.createElement(component, props))}
      />
    );
  };

  return (
    <div>
      <BrowserRouter>
        <Switch>
          <PublicRoute exact path="/login" component={Login} />
          <PrivateRoute component={AppLayout} />
        </Switch>
      </BrowserRouter>
    </div>
  );
};

export default App;
