const fileItemList = [
	{ name: '租約', needGetTime: true },
	{ name: '併聯審查意見書', needGetTime: true },
	{ name: '同意備案函', needGetTime: true },
	{ name: '建置工程合約', needGetTime: true },
	{ name: '電能購售契約', needGetTime: true },
	{ name: '併聯試運轉函', needGetTime: true },
	{ name: '設備登記函', needGetTime: true },
	{ name: '正式購售電能函', needGetTime: true },
	{ name: '服務建議書', needGetTime: false },
	{ name: '共同開發合約', needGetTime: false },
	{ name: '維運合約', needGetTime: false },
	{ name: '電廠擁有者帳戶影本', needGetTime: false }
];
export default fileItemList;
