import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Table, Button, Icon, message, Modal } from 'antd';
import { numberFormat } from '../Utils/Format';
import { getToken } from '../../utils/setting';

class PvBill extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataSource: [],
    };
  }

  componentDidMount() {
    this.loadData();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.changed) {
      this.loadData();
    }
  }

  loadData() {
    return axios
      .get(`/api/bill/allForCheck/${this.props.id}`, { headers: { Authorization: getToken() } })
      .then((res) => {
        if (res.data.status) {
          this.setState({ dataSource: res.data.result.bills });
        } else {
          message.error(res.data.msg);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  handleCheck = (index) => {
    axios
      .post(
        `/api/bill/check`,
        {
          index,
        },
        { headers: { Authorization: getToken() } }
      )
      .then((res) => {
        if (res.data.status) {
          this.loadData();
        } else {
          Modal.error({
            title: '錯誤訊息',
            content: res.data.msg,
          });
        }
      })
      .catch((error) => {
        Modal.error({
          title: '錯誤訊息',
          content: error.response.data.msg || '很抱歉, 系統發生錯誤, 請稍後重試或聯絡系統管理員',
        });
      });
  };

  handleReject = (index) => {
    axios
      .post(
        `/api/bill/reject`,
        {
          index,
        },
        { headers: { Authorization: getToken() } }
      )
      .then((res) => {
        if (res.data.status) {
          this.loadData();
        } else {
          Modal.error({
            title: '錯誤訊息',
            content: res.data.msg,
          });
        }
      })
      .catch((error) => {
        Modal.error({
          title: '錯誤訊息',
          content: error.response.data.msg || '很抱歉, 系統發生錯誤, 請稍後重試或聯絡系統管理員',
        });
      });
  };

  handleSync = (index) => {
    axios
      .post(
        `/api/bill/sync`,
        {
          index,
        },
        { headers: { Authorization: getToken() } }
      )
      .then((res) => {
        if (res.data.status) {
          this.loadData();
        } else {
          Modal.error({
            title: '錯誤訊息',
            content: res.data.msg,
          });
        }
      })
      .catch((error) => {
        Modal.error({
          title: '錯誤訊息',
          content: error.response.data.msg || '很抱歉, 系統發生錯誤, 請稍後重試或聯絡系統管理員',
        });
      });
  };

  render() {
    const padding = 32;
    const fontSize = 14;
    const columns = [
      {
        title: '檔案',
        dataIndex: 'file',
        key: 'file',
        width: padding + fontSize * 6,
        fixed: 'left',
        align: 'center',
        render: (text, record) => {
          if (record.file) {
            return (
              <Link to={`/api/upload/file/${record.file}`} target="_blank">
                查看
              </Link>
            );
          } else {
            return null;
          }
        },
      },
      {
        title: '計費起始日',
        dataIndex: 'rangeBegin',
        width: padding + fontSize * 6,
        fixed: 'left',
        key: 'rangeBillBegin',
      },
      {
        title: '計費結束日',
        dataIndex: 'rangeEnd',
        width: padding + fontSize * 6,
        fixed: 'left',
        key: 'rangeBillEnd',
        render: (v) => moment(v).subtract(1, 'days').format('YYYY-MM-DD'),
      },
      {
        title: '上次抄表日',
        dataIndex: 'rangeBegin',
        width: padding + fontSize * 6,
        key: 'rangeBegin',
        align: 'center',
      },
      {
        title: '本次抄表日',
        dataIndex: 'rangeEnd',
        width: padding + fontSize * 6,
        key: 'rangeEnd',
        align: 'center',
      },
      {
        title: '發電度數',
        dataIndex: 'totalPower',
        width: padding + fontSize * 6,
        key: 'totalPower',
        align: 'right',
        render: (v) => numberFormat({ value: v, postfix: '度' }),
      },
      {
        title: '發電金額',
        dataIndex: 'powerAmount',
        width: padding + fontSize * 5,
        key: 'powerAmount',
        align: 'right',
        render: (v) => numberFormat({ value: v, postfix: '元' }),
      },
      {
        title: '補付(扣)電費',
        dataIndex: 'deductPowerAmount',
        width: padding + fontSize * 6,
        key: 'deductAmount',
        align: 'right',
        render: (v) => numberFormat({ value: v, postfix: '元' }),
      },
      {
        title: '營業稅',
        dataIndex: 'salesTax',
        width: padding + fontSize * 6,
        key: 'salesTax',
        align: 'right',
        render: (v) => numberFormat({ value: v, postfix: '元' }),
      },
      {
        title: '總電費(含稅)',
        dataIndex: 'incomeWithTax',
        width: padding + fontSize * 6,
        key: 'incomeWithTax',
        align: 'right',
        render: (v) => numberFormat({ value: v, postfix: '元' }),
      },
      {
        title: '本期電表租費',
        dataIndex: 'powerRent',
        width: padding + fontSize * 6,
        key: 'powerRent',
        align: 'right',
        render: (v) => numberFormat({ value: v, postfix: '元' }),
      },
      {
        title: '電表租費收(退)',
        dataIndex: 'makeupPowerRent',
        width: padding + fontSize * 7,
        key: 'makeupRent',
        align: 'right',
        render: (v) => numberFormat({ value: v, postfix: '元' }),
      },
      {
        title: '電表租費',
        dataIndex: 'meterRentCost',
        width: padding + fontSize * 6,
        key: 'meterRentCost',
        align: 'right',
        render: (v) => numberFormat({ value: v, postfix: '元' }),
      },
      {
        title: '狀態',
        dataIndex: 'status',
        key: 'status',
        width: padding + fontSize * 6,
        fixed: 'right',
        align: 'center',
        render: (text, record) => (record.deleted ? '刪除' : ''),
      },
      {
        title: '審核狀態',
        key: 'checkStatus',
        width: padding + fontSize * 4,
        fixed: 'right',
        render: (text, record) => {
          if (record.rejectTime) {
            if (record.checkTime) {
              if (record.rejectTime > record.checkTime && record.rejectTime > record.updateTime) {
                return <div style={{ color: 'red' }}>否決中</div>;
              }
            } else if (record.rejectTime > record.updateTime) {
              return <div style={{ color: 'red' }}>否決中</div>;
            }
          }

          return null;
        },
      },
      {
        title: '操作',
        key: 'action',
        width: padding + fontSize * 6,
        fixed: 'right',
        align: 'center',
        render: (text, record) => {
          if (this.props.forceRejectMode) {
            if (!(record.updateTime <= record.rejectTime)) {
              return (
                <Button onClick={() => this.handleReject(record.index)}>
                  <Icon type="close" />
                  否決
                </Button>
              );
            } else {
              return null;
            }
          } else if (!(record.updateTime <= record.checkTime) && !(record.updateTime <= record.rejectTime)) {
            return (
              <div>
                <Button onClick={() => this.handleCheck(record.index)}>
                  <Icon type="check" />
                  核准
                </Button>
                <Button onClick={() => this.handleReject(record.index)}>
                  <Icon type="close" />
                  否決
                </Button>
              </div>
            );
          } else if (
            record.checkTime &&
            !(record.checkTime <= record.rejectTime) &&
            !(record.syncTime >= record.checkTime)
          ) {
            return (
              <Button onClick={() => this.handleSync(record.index)}>
                <Icon type="sync" />
                同步
              </Button>
            );
          } else {
            return null;
          }
        },
      },
    ];

    const totalWidth = columns.reduce((total, elem) => {
      return total + elem.width;
    }, 0);
    return (
      <div>
        <Table
          dataSource={this.state.dataSource}
          columns={columns}
          defaultPageSize={5}
          scroll={{ x: totalWidth }}
        />
      </div>
    );
  }
}

export default PvBill;
