import React, { useMemo, useState } from 'react';
import { BrowserRouter, Switch, Route, Link } from 'react-router-dom';
import axios from 'axios';
import { Layout, Menu, Icon, Button, Modal } from 'antd';

//
import Col from './components/Commons/Col';
import Divider from './components/Commons/Divider';
import JModal from './components/Commons/Modal';
import Password from './components/Commons/Input/password';
import Row from './components/Commons/Row';
import Subtitle from './components/Commons/Subtitle';

import Home from './components/Home';
import PvCreate from './components/PvCreate';
import PvQuery1 from './components/PvQuery1';
import PvQuery2 from './components/PvQuery2';
import PvQuery3 from './components/PvQuery3';
import PvMaintain1 from './components/PvMaintain1';
import PvMaintain2 from './components/PvMaintain2';
import PvMaintain3 from './components/PvMaintain3';
import SpvQuery3 from './components/SpvQuery3';
import SpvMaintain3 from './components/SpvMaintain3';
import BillSettlement from './components/BillSettlement';
import PowerMonitor from './components/PowerMonitor';
import PowerHistory from './components/PowerHistory';
import PageNotFound from './components/PageNotFound';

import { useProvided } from './store/index';
import useAuth from './store/useAuth';

const { Footer, Sider } = Layout;
const SubMenu = Menu.SubMenu;

const InitEditData = {
  oldPwd: { value: '', msg: '' },
  newPwd: { value: '', msg: '' },
  doubleCheckPwd: { value: '', msg: '' },
};
const AppLayout = () => {
  const { user, UnAuthUser } = useProvided(useAuth);
  const [modalOpen, setModalOpen] = useState(false);
  const [editData, setEditData] = useState(InitEditData);

  const handleModalOpen = () => {
    setModalOpen(true);
  };
  const handleModalClose = () => {
    setEditData(InitEditData);
    setModalOpen(false);
  };
  const handleInputChange = (name) => (e) => {
    if (e && e.target) {
      const tmp = { ...editData };
      const tmpValue = e.target.value;
      let errMsg = '';
      switch (name) {
        case 'oldPwd':
          if (tmpValue.length >= 4) {
            if (tmp.newPwd.value === tmpValue) {
              tmp.newPwd.value = '';
              tmp.newPwd.msg = '新密碼不得與舊密碼相同';
            }
          } else {
            errMsg = '密碼長度不足';
          }
          break;
        case 'newPwd':
          if (tmpValue.length >= 4) {
            if (tmp.oldPwd.value !== tmpValue) {
              if (!tmp.doubleCheckPwd.value || tmp.doubleCheckPwd.value === tmpValue) {
                tmp.doubleCheckPwd.msg = '';
              } else {
                tmp.doubleCheckPwd.msg = '輸入的密碼與新密碼並不相符';
              }
            } else {
              errMsg = '請輸入密碼, 且新密碼不得與舊密碼相同';
            }
          } else {
            errMsg = '密碼長度不足';
          }
          break;
        case 'doubleCheckPwd':
          if (tmp.newPwd.value !== tmpValue) {
            errMsg = '輸入的密碼與新密碼並不相符';
          }
          break;
        default:
          console.error('should not have this case');
          break;
      }
      tmp[name] = { value: tmpValue, msg: errMsg };
      setEditData(tmp);
    } else {
      //Notify.error('密碼安全性驗證出現錯誤');
      Modal.error({
        title: '訊息提示',
        content: '密碼安全性驗證出現錯誤',
      });
    }
  };
  const handlePwdModify = () => {
    if (editData.oldPwd.msg || editData.newPwd.msg || editData.doubleCheckPwd.msg) {
      //Notify.error('輸入的資料仍有誤，請先修正');
      Modal.error({
        title: '訊息提示',
        content: '輸入的資料仍有誤，請先修正',
      });
      return;
    }
    if (!editData.oldPwd.value || !editData.newPwd.value || !editData.doubleCheckPwd.value) {
      //Notify.error('請輸入新舊密碼');
      Modal.error({
        title: '訊息提示',
        content: '請輸入新舊密碼',
      });
      return;
    }
    axios
      .post(
        '/api/user/pwd/reset',
        {
          pwd: editData.oldPwd.value,
          newPwd: editData.newPwd.value,
          newPwdChecked: editData.doubleCheckPwd.value,
        },
        { headers: { Authorization: user.token } }
      )
      .then((res) => {
        if (res && res.data && res.data.status) {
          //Notify.success('設定成功，下次登入請使用新密碼');
          Modal.success({
            title: '訊息提示',
            content: '設定成功，下次登入請使用新密碼',
          });
          handleModalClose();
        } else {
          //Notify.error('密碼重設失敗，' + res.data.msg);
          Modal.error({
            title: '錯誤訊息',
            content: '密碼重設失敗，' + res.data.msg,
          });
          setEditData(InitEditData);
        }
      })
      .catch((err) => {
        console.error(err);
        //Notify.error('密碼重設失敗');
        Modal.error({
          title: '錯誤訊息',
          content: '密碼重設失敗',
        });
        setEditData(InitEditData);
      });
  };

  const menus = useMemo(() => {
    const m = {
      home: { key: 'dashboard', icon: 'dashboard', name: 'dashboard', linkTo: '/' },
      pv: { key: 'shop', icon: 'shop', name: '電廠', linkTo: null, sub: [] },
      spv: null,
      power: {
        key: 'power',
        icon: 'thunderbolt',
        name: '發電',
        linkTo: null,
        sub: [
          { key: 'powerMonitor', icon: null, name: '本日監控', linkTo: '/powerMonitor' },
          { key: 'powerHistory', icon: null, name: '歷史線圖', linkTo: '/powerHistory' },
        ],
      },
      //bill: { key: 'bill', icon: 'dollar', name: '帳務', linkTo: null, sub: [{ key: 'billSettlement', icon: null, name: '電費結算', linkTo: '/billSettlement' }, { key: 'pvAccouting', icon: null, name: '其他帳務', linkTo: '/pvAccouting' }] }
      logout: {
        key: 'user',
        icon: 'user',
        name: user.account,
        linkTo: null,
        sub: [
          { key: 'logout', icon: null, name: '登出', linkTo: '/login', clickFn: UnAuthUser },
          { key: 'resetPwd', icon: null, name: '變更密碼', linkTo: null, clickFn: handleModalOpen },
        ],
      },
    };

    switch (user.role) {
      case 'aily':
        m.pv.sub.push({ key: 'pvCreate', icon: null, name: '新增', linkTo: '/pvCreate' });
        m.pv.sub.push({ key: 'pvQuery1', icon: null, name: '微電查詢', linkTo: '/pvQuery1' });
        break;
      case 'check':
      case 'firstCheck':
      case 'secondCheck':
        m.pv.sub.push({ key: 'pvQuery3', icon: null, name: '查詢', linkTo: '/pvQuery3' });
        m.pv.sub.push({ key: 'pvQuery2', icon: null, name: '審核', linkTo: '/pvQuery2' });
        break;
      case 'sola':
        m.pv.sub.push({ key: 'pvQuery3', icon: null, name: '索拉查詢', linkTo: '/pvQuery3' });
        //
        m.spv = { key: 'spv', icon: 'home', name: 'SPV', linkTo: null, sub: [] };
        m.spv.sub.push({ key: 'spvQuery3', icon: null, name: '索拉查詢', linkTo: '/spvQuery3' });
        break;
      case 'sysAdmin':
        m.pv.sub.push({ key: 'pvCreate', icon: null, name: '新增', linkTo: '/pvCreate' });
        m.pv.sub.push({ key: 'pvQuery1', icon: null, name: '微電查詢', linkTo: '/pvQuery1' });
        m.pv.sub.push({ key: 'pvQuery2', icon: null, name: '審核', linkTo: '/pvQuery2' });
        m.pv.sub.push({ key: 'pvQuery3', icon: null, name: '索拉查詢', linkTo: '/pvQuery3' });
        m.spv = { key: 'spv', icon: 'home', name: 'SPV', linkTo: null, sub: [] };
        m.spv.sub.push({ key: 'spvQuery3', icon: null, name: '索拉查詢', linkTo: '/spvQuery3' });
        break;
      default:
        break;
    }
    return m;
  }, [user, UnAuthUser]);

  const routes = useMemo(() => {
    const r = [];
    switch (user.role) {
      case 'aily':
        r.push(<Route key={0} path="/pvCreate" component={PvCreate} />);
        r.push(<Route key={1} path="/pvQuery1" component={PvQuery1} />);
        r.push(<Route key={2} path="/pvMaintain1/:pvId" component={PvMaintain1} />);
        break;
      case 'check':
      case 'firstCheck':
      case 'secondCheck':
        r.push(<Route key={5} path="/pvQuery3" component={PvQuery3} />);
        r.push(<Route key={6} path="/pvMaintain3/:pvId" component={PvMaintain3} />);
        r.push(<Route key={3} path="/pvQuery2" component={PvQuery2} />);
        r.push(<Route key={4} path="/pvMaintain2/:pvId" component={PvMaintain2} />);
        break;
      case 'sola':
        r.push(<Route key={5} path="/pvQuery3" component={PvQuery3} />);
        r.push(<Route key={6} path="/pvMaintain3/:pvId" component={PvMaintain3} />);
        r.push(<Route key={7} path="/spvQuery3" component={SpvQuery3} />);
        r.push(<Route key={8} path="/spvMaintain3/:spvId" component={SpvMaintain3} />);
        break;
      case 'sysAdmin':
        r.push(<Route key={0} path="/pvCreate" component={PvCreate} />);
        r.push(<Route key={1} path="/pvQuery1" component={PvQuery1} />);
        r.push(<Route key={2} path="/pvMaintain1/:pvId" component={PvMaintain1} />);
        r.push(<Route key={3} path="/pvQuery2" component={PvQuery2} />);
        r.push(<Route key={4} path="/pvMaintain2/:pvId" component={PvMaintain2} />);
        r.push(<Route key={5} path="/pvQuery3" component={PvQuery3} />);
        r.push(<Route key={6} path="/pvMaintain3/:pvId" component={PvMaintain3} />);
        r.push(<Route key={7} path="/spvQuery3" component={SpvQuery3} />);
        r.push(<Route key={8} path="/spvMaintain3/:spvId" component={SpvMaintain3} />);
        break;
      default:
        break;
    }
    return r;
  }, [user]);

  return (
    <BrowserRouter>
      <Layout>
        <Sider style={{ overflow: 'auto', height: '100vh', position: 'fixed', left: 0 }}>
          <Menu theme="dark" mode="inline" defaultSelectedKeys={['dashboard']}>
            {menus &&
              Object.keys(menus).map((subMenuKey) => {
                const item = menus[subMenuKey];

                if (!item) return null;

                if (Array.isArray(item.sub) && item.sub.length > 0) {
                  //用 SubMenu
                  const subItem = item.sub.map((sub) => {
                    return (
                      <Menu.Item key={sub.key}>
                        {sub.linkTo ? (<Link to={sub.linkTo} onClick={sub.clickFn}>
                          {sub.icon && <Icon type={sub.icon} />}
                          <span>{sub.name}</span>
                        </Link>) :
                          (<span onClick={sub.clickFn}>
                            {sub.icon && <Icon type={sub.icon} />}
                            <span>{sub.name}</span>
                          </span>)}
                      </Menu.Item>
                    );
                  });

                  return (
                    <SubMenu
                      key={item.key}
                      title={
                        <span>
                          <Icon type={item.icon} />
                          <span>{item.name}</span>
                        </span>
                      }
                    >
                      {subItem}
                    </SubMenu>
                  );
                } else {
                  //用 Menu.Item
                  if (item.linkTo) {
                    return (
                      <Menu.Item key={item.key}>
                        <Link to={item.linkTo}>
                          {item.icon && <Icon type={item.icon} />}
                          <span>{item.name}</span>
                        </Link>
                      </Menu.Item>
                    );
                  } else {
                    return (
                      <Menu.Item key={item.key}>
                        {item.icon && <Icon type={item.icon} />}
                        <span>{item.name}</span>
                      </Menu.Item>
                    );
                  }
                }
              })}
          </Menu>
        </Sider>
        <Layout style={{ marginLeft: 200 }}>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/billSettlement" component={BillSettlement} />
            <Route path="/powerMonitor" component={PowerMonitor} />
            <Route path="/powerHistory" component={PowerHistory} />
            {routes}
            <Route component={PageNotFound} />
          </Switch>
          <Footer style={{ textAlign: 'center' }}>Powered by SOLA</Footer>
        </Layout>
      </Layout>
      <JModal visible={modalOpen} width="600px">
        <Subtitle dense>變更密碼</Subtitle>
        <Row style={{ padding: '0px 15px' }}>
          <Col span={24}>
            <Password
              label="舊密碼"
              placeholder="請輸入舊的密碼"
              value={editData.oldPwd.value}
              msg={editData.oldPwd.msg}
              onChange={handleInputChange('oldPwd')}
            />
          </Col>
          <Col span={24}>
            <Password
              label="新密碼"
              placeholder="請輸入新密碼"
              value={editData.newPwd.value}
              msg={editData.newPwd.msg}
              onChange={handleInputChange('newPwd')}
            />
          </Col>
          <Col span={24}>
            <Password
              label="確認新密碼"
              placeholder="請再次輸入新密碼"
              value={editData.doubleCheckPwd.value}
              msg={editData.doubleCheckPwd.msg}
              onChange={handleInputChange('doubleCheckPwd')}
            />
          </Col>
        </Row>
        <Divider />
        <Row justify="end" align="middle">
          <Col>
            <Button type="primary" onClick={handlePwdModify}>
              確認修改
              </Button>
          </Col>
          <Col>
            <Button onClick={handleModalClose}>取消</Button>
          </Col>
        </Row>
      </JModal>
    </BrowserRouter>
  );
};

export default AppLayout;
