import React, { Component } from 'react';
import axios from 'axios';

import valueToName from './Definition/ValueToName';
import stageList from './Definition/StageList';
import statusList from './Definition/StatusList';
import withTaxList from './Definition/WithTaxList';
import withMeterList from './Definition/WithMeterList';
import decayList from './Definition/DecayList';
import { numberFormat, percentFormat } from './Utils/Format';
import { getToken } from '../utils/setting';

import {
  Form,
  Input,
  Tooltip,
  Icon,
  Select,
  Button,
  InputNumber,
  DatePicker,
  Layout,
  Modal,
  Card,
} from 'antd';
const FormItem = Form.Item;
const Option = Select.Option;
const { Header, Content } = Layout;

class CreatePvForm extends Component {
  constructor(props) {
    super(props);

    this.handleOk = this.handleOk.bind(this);
    this.handleCancel = this.handleCancel.bind(this);

    this.state = {
      modalTitle: '',
      modalData: '',
      visible: false,
      confirmLoading: false,
      values: null,
    };
  }

  showModal = (values) => {
    const data = (
      <div>
        <div>電廠名稱: {values.name !== null ? values.name : '待補'}</div>
        <div>專案代碼: {values.projectCode !== null ? values.projectCode : '待補'}</div>
        <div>電廠擁有者: {values.owner !== null ? values.owner : '待補'}</div>
        <div>電廠地址: {values.location !== null ? values.location : '待補'}</div>
        <div>GPS緯度: {values.coordLat !== null ? values.coordLat : '待補'}</div>
        <div>GPS經度: {values.coordLng !== null ? values.coordLng : '待補'}</div>
        <div>
          裝置總容量:{' '}
          {values.capacity !== null ? numberFormat({ value: values.capacity, postfix: 'kWp' }) : '待補'}
        </div>
        <div>發電年衰減率: {valueToName(decayList, values.decay)}</div>
        <div>
          年保證發電量:{' '}
          {values.annualAmount !== null
            ? numberFormat({ value: values.annualAmount, postfix: 'kWp' })
            : '待補'}
        </div>
        <div>建置階段: {valueToName(stageList, values.stage)}</div>
        <div>狀態: {valueToName(statusList, values.status)}</div>
        <div>
          單位成本:{' '}
          {values.constructionCost !== null
            ? numberFormat({ value: values.constructionCost, postfix: '元' })
            : '待補'}
        </div>
        <div>
          總成本:{' '}
          {values.totalCapital !== null
            ? numberFormat({ value: values.totalCapital, postfix: '元' })
            : '待補'}
        </div>
        <div>
          理論投資金額:{' '}
          {values.ownedCapital !== null
            ? numberFormat({ value: values.ownedCapital, postfix: '元' })
            : '待補'}
        </div>
        <div>
          結算起始日:{' '}
          {values.validityDateBegin !== null ? values.validityDateBegin.format('YYYY-MM-DD') : '待補'}
        </div>
        <div>
          結算年限:{' '}
          {values.validityDuration !== null
            ? numberFormat({ value: values.validityDuration, postfix: '年' })
            : '待補'}
        </div>
        <div>
          掛表日: {values.meterBeginDate !== null ? values.meterBeginDate.format('YYYY-MM-DD') : '待補'}
        </div>
        <div>
          售電價格:{' '}
          {values.sellPrice !== null ? numberFormat({ value: values.sellPrice, postfix: '元' }) : '待補'}
        </div>
        <div>
          營運固定費率:{' '}
          {values.operationRateByCapital !== null ? percentFormat(values.operationRateByCapital) : '待補'}
        </div>
        <div>
          營運浮動費率:{' '}
          {values.operationRateByIncome !== null ? percentFormat(values.operationRateByIncome) : '待補'}
        </div>
        <div>
          索拉服務費率:{' '}
          {values.serviceRateByIncome !== null ? percentFormat(values.serviceRateByIncome) : '待補'}
        </div>
        <div>
          索拉服務起始日:{' '}
          {values.serviceBeginDate !== null ? values.serviceBeginDate.format('YYYY-MM-DD') : '待補'}
        </div>
        <div>
          租金固定費用(年):{' '}
          {values.rentYearCost !== null
            ? numberFormat({ value: values.rentYearCost, postfix: '元' })
            : '待補'}
        </div>
        <div>
          租金浮動費率: {values.rentRateByIncome !== null ? percentFormat(values.rentRateByIncome) : '待補'}
        </div>
        <div>
          浮動租金計費基礎:{' '}
          {values.rentWithTax !== null ? valueToName(withTaxList, values.rentWithTax) : '待補'}
        </div>
        <div>
          浮動租金計費負擔:{' '}
          {values.rentWithMeter !== null ? valueToName(withMeterList, values.rentWithMeter) : '待補'}
        </div>
      </div>
    );

    this.setState({
      modalTitle: '電廠資料是否正確，確定要建置此資料?',
      modalData: data,
      visible: true,
      values: values,
    });
  };

  handleOk = () => {
    this.setState({
      modalTitle: '電廠建置結果',
      modalData: '正在進行建置，請稍候',
      confirmLoading: true,
    });

    axios
      .post(`/api/pv/create`, { ...this.state.values }, { headers: { Authorization: getToken() } })
      .then((res) => {
        if (res.data.status === true) {
          this.setState({
            modalData: '建置完成',
            confirmLoading: false,
            values: null,
          });

          this.props.form.resetFields();
        } else {
          this.setState({
            modalData: `建置失敗, 錯誤訊息: ${res.data.msg}`,
            confirmLoading: false,
            values: null,
          });
        }
      })
      .catch((error) => {
        this.setState({
          modalData: error.response.data.msg || '很抱歉, 系統發生錯誤, 請稍後重試或聯絡系統管理員',
          confirmLoading: false,
          values: null,
        });
      });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
      confirmLoading: false,
      values: null,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values);
        this.showModal(values);
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
    };

    const tailFormItemLayout = {
      wrapperCol: {
        span: 8,
        offset: 6,
      },
    };

    let footer = [];
    if (this.state.values && !this.state.confirmLoading) {
      footer.push(
        <Button key="cancel" onClick={this.handleCancel}>
          取消
        </Button>
      );
      footer.push(
        <Button key="ok" type="primary" loading={this.state.confirmLoading} onClick={this.handleOk}>
          確認
        </Button>
      );
    } else {
      footer.push(
        <Button key="ok" type="primary" loading={this.state.confirmLoading} onClick={this.handleCancel}>
          確認
        </Button>
      );
    }

    return (
      <div>
        <Header
          style={{
            background: '#fff',
            padding: 0,
            textAlign: 'center',
            fontSize: '2em',
          }}
        >
          建置新的電廠
        </Header>
        <Content style={{ margin: '24px 16px 0', overflow: 'initial' }}>
          <Card style={{ width: '100%' }}>
            <Form onSubmit={this.handleSubmit}>
              <FormItem
                {...formItemLayout}
                label={
                  <span>
                    電廠名稱&nbsp;
                    <Tooltip title="電廠名稱">
                      <Icon type="question-circle-o" />
                    </Tooltip>
                  </span>
                }
              >
                {getFieldDecorator('name', {
                  rules: [
                    {
                      required: true,
                      message: '請輸入電廠名稱',
                    },
                  ],
                })(<Input placeholder="雲林褒忠雞舍1" />)}
              </FormItem>
              <FormItem
                {...formItemLayout}
                label={
                  <span>
                    專案代碼&nbsp;
                    <Tooltip title="微電專案代碼">
                      <Icon type="question-circle-o" />
                    </Tooltip>
                  </span>
                }
              >
                {getFieldDecorator('projectCode', {
                  initialValue: null,
                  rules: [
                    {
                      message: '請輸入專案代碼',
                    },
                  ],
                })(<Input placeholder="T6308" />)}
              </FormItem>
              <FormItem
                {...formItemLayout}
                label={
                  <span>
                    電廠擁有者&nbsp;
                    <Tooltip title="電廠擁有者">
                      <Icon type="question-circle-o" />
                    </Tooltip>
                  </span>
                }
              >
                {getFieldDecorator('owner', {
                  initialValue: null,
                  rules: [
                    {
                      message: '請給電廠一個擁有者',
                    },
                  ],
                })(<Input placeholder="東迪股份有限公司" />)}
              </FormItem>
              <FormItem {...formItemLayout} label="電廠地址">
                {getFieldDecorator('location', {
                  initialValue: null,
                  rules: [
                    {
                      message: '請輸入電廠地址',
                    },
                  ],
                })(<Input placeholder="雲林縣褒忠鄉馬鳴村鎮安路203-3號" />)}
              </FormItem>
              <FormItem {...formItemLayout} label="緯度">
                {getFieldDecorator('coordLat', {
                  initialValue: null,
                  rules: [
                    {
                      type: 'number',
                      message: '請輸入緯度值',
                    },
                  ],
                })(<InputNumber style={{ width: '200px' }} placeholder="23.702838" />)}
              </FormItem>
              <FormItem {...formItemLayout} label="經度">
                {getFieldDecorator('coordLng', {
                  initialValue: null,
                  rules: [
                    {
                      type: 'number',
                      message: '請輸入經度值',
                    },
                  ],
                })(<InputNumber style={{ width: '200px' }} placeholder="120.274352" />)}
              </FormItem>
              <FormItem {...formItemLayout} label="裝置總容量">
                {getFieldDecorator('capacity', {
                  initialValue: null,
                  rules: [
                    {
                      type: 'number',
                      message: '請輸入裝置總容量',
                    },
                  ],
                })(<InputNumber style={{ width: '200px' }} min={1} placeholder="99.9" />)}
                <span className="ant-form-text"> kWp</span>
              </FormItem>
              <FormItem {...formItemLayout} label="發電年衰減率">
                {getFieldDecorator('decay', {
                  initialValue: 0.007,
                  rules: [
                    {
                      type: 'number',
                      message: '請選擇發電年衰減率',
                    },
                  ],
                })(
                  <Select placeholder="請選擇發電年衰減率">
                    {decayList.map((v, i) => (
                      <Option key={i} value={v.value}>
                        {v.name}
                      </Option>
                    ))}
                  </Select>
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="年保證發電量">
                {getFieldDecorator('annualAmount', {
                  initialValue: null,
                  rules: [
                    {
                      type: 'number',
                      message: '請輸入年保證發電量',
                    },
                  ],
                })(<InputNumber style={{ width: '200px' }} min={1} placeholder="1350" />)}
                <span className="ant-form-text"> kWp</span>
              </FormItem>
              <FormItem
                {...formItemLayout}
                label={
                  <span>
                    建置階段&nbsp;
                    <Tooltip title="建置階段">
                      <Icon type="question-circle-o" />
                    </Tooltip>
                  </span>
                }
              >
                {getFieldDecorator('stage', {
                  initialValue: 0,
                  rules: [
                    {
                      type: 'number',
                      message: '請選擇建置階段',
                    },
                  ],
                })(
                  <Select placeholder="請選擇建置階段">
                    {stageList.map((v, i) => (
                      <Option key={i} value={v.value}>
                        {v.name}
                      </Option>
                    ))}
                  </Select>
                )}
              </FormItem>
              <FormItem
                {...formItemLayout}
                label={
                  <span>
                    狀態&nbsp;
                    <Tooltip title="狀態">
                      <Icon type="question-circle-o" />
                    </Tooltip>
                  </span>
                }
              >
                {getFieldDecorator('status', {
                  initialValue: 1,
                  rules: [
                    {
                      type: 'number',
                      message: '請選擇狀態',
                    },
                  ],
                })(
                  <Select placeholder="請選擇狀態">
                    {statusList.map((v, i) => (
                      <Option key={i} value={v.value}>
                        {v.name}
                      </Option>
                    ))}
                  </Select>
                )}
              </FormItem>
              <FormItem
                {...formItemLayout}
                label={
                  <span>
                    單位成本&nbsp;
                    <Tooltip title="電廠建置單位成本">
                      <Icon type="question-circle-o" />
                    </Tooltip>
                  </span>
                }
              >
                {getFieldDecorator('constructionCost', {
                  initialValue: null,
                  rules: [
                    {
                      type: 'number',
                      message: '請輸入單位成本',
                    },
                  ],
                })(<InputNumber style={{ width: '200px' }} min={1} placeholder="54,000" />)}
                <span className="ant-form-text"> 元</span>
              </FormItem>
              <FormItem
                {...formItemLayout}
                label={
                  <span>
                    建置總成本&nbsp;
                    <Tooltip title="電廠建置總成本">
                      <Icon type="question-circle-o" />
                    </Tooltip>
                  </span>
                }
              >
                {getFieldDecorator('totalCapital', {
                  initialValue: null,
                  rules: [
                    {
                      type: 'number',
                      message: '請輸入電廠總成本',
                    },
                  ],
                })(<InputNumber style={{ width: '200px' }} min={1} placeholder="6,398,595" />)}
                <span className="ant-form-text"> 元</span>
              </FormItem>
              <FormItem
                {...formItemLayout}
                label={
                  <span>
                    理論投資金額&nbsp;
                    <Tooltip title="扣除貸款，投資人理論應投資的金額，系統會依據此成本發行收益權數量">
                      <Icon type="question-circle-o" />
                    </Tooltip>
                  </span>
                }
              >
                {getFieldDecorator('ownedCapital', {
                  initialValue: null,
                  rules: [
                    {
                      type: 'number',
                      message: '請輸入理論投資金額',
                    },
                  ],
                })(<InputNumber style={{ width: '200px' }} min={1} placeholder="1,699,549" />)}
                <span className="ant-form-text"> 元</span>
              </FormItem>
              <FormItem
                {...formItemLayout}
                label={
                  <span>
                    結算起始日&nbsp;
                    <Tooltip title="電費計算開始日期">
                      <Icon type="question-circle-o" />
                    </Tooltip>
                  </span>
                }
              >
                {getFieldDecorator('validityDateBegin', {
                  initialValue: null,
                  rules: [
                    {
                      type: 'object',
                      message: '請輸入有效起始日期',
                    },
                  ],
                })(<DatePicker placeholder="請選擇結算起始日期" />)}
              </FormItem>
              <FormItem {...formItemLayout} label="結算年限">
                {getFieldDecorator('validityDuration', {
                  initialValue: null,
                  rules: [
                    {
                      type: 'integer',
                      message: '請輸入結算年限',
                    },
                  ],
                })(<InputNumber style={{ width: '200px' }} min={1} placeholder="20" />)}
                <span className="ant-form-text"> 年</span>
              </FormItem>
              <FormItem
                {...formItemLayout}
                label={
                  <span>
                    掛表日&nbsp;
                    <Tooltip title="依據台電售電合約的掛表日">
                      <Icon type="question-circle-o" />
                    </Tooltip>
                  </span>
                }
              >
                {getFieldDecorator('meterBeginDate', {
                  initialValue: null,
                  rules: [
                    {
                      type: 'object',
                      message: '請輸入有效起始日期',
                    },
                  ],
                })(<DatePicker placeholder="請選擇掛表日" />)}
              </FormItem>
              <FormItem
                {...formItemLayout}
                label={
                  <span>
                    售電價格&nbsp;
                    <Tooltip title="與台電簽訂的購電價格">
                      <Icon type="question-circle-o" />
                    </Tooltip>
                  </span>
                }
              >
                {getFieldDecorator('sellPrice', {
                  initialValue: null,
                  rules: [
                    {
                      type: 'float',
                      message: '請輸入售電價格',
                    },
                  ],
                })(<InputNumber style={{ width: '200px' }} min={0} placeholder="5.2758" />)}
                <span className="ant-form-text"> 元</span>
              </FormItem>
              <FormItem
                {...formItemLayout}
                label={
                  <span>
                    營運固定費率&nbsp;
                    <Tooltip title="由系統總成本之比例，做為電廠維運所需之年費用, ex: 0.0105, 若無則填0">
                      <Icon type="question-circle-o" />
                    </Tooltip>
                  </span>
                }
              >
                {getFieldDecorator('operationRateByCapital', {
                  initialValue: null,
                  rules: [
                    {
                      type: 'number',
                      message: '請輸入營運固定費率',
                    },
                  ],
                })(<InputNumber style={{ width: '200px' }} min={0} placeholder="0.0105" />)}
              </FormItem>
              <FormItem
                {...formItemLayout}
                label={
                  <span>
                    營運浮動費率&nbsp;
                    <Tooltip title="由電費提撥部份比例，做為電廠維運所需之費用, ex: 0.11, 若無則填0">
                      <Icon type="question-circle-o" />
                    </Tooltip>
                  </span>
                }
              >
                {getFieldDecorator('operationRateByIncome', {
                  initialValue: null,
                  rules: [
                    {
                      type: 'number',
                      message: '請輸入營運浮動費率',
                    },
                  ],
                })(<InputNumber style={{ width: '200px' }} min={0} placeholder="0.11" />)}
              </FormItem>
              <FormItem
                {...formItemLayout}
                label={
                  <span>
                    索拉服務費率&nbsp;
                    <Tooltip title="由電費提撥部份比例，做為SOLA平台收取之服務費用, ex: 0.035">
                      <Icon type="question-circle-o" />
                    </Tooltip>
                  </span>
                }
              >
                {getFieldDecorator('serviceRateByIncome', {
                  initialValue: null,
                  rules: [
                    {
                      type: 'number',
                      message: '請輸入索拉服務費率',
                    },
                  ],
                })(<InputNumber style={{ width: '200px' }} min={0} placeholder="0.035" />)}
              </FormItem>
              <FormItem
                {...formItemLayout}
                label={
                  <span>
                    索拉服務起始日&nbsp;
                    <Tooltip title="依據服務合約日期, 未填則視為從結算起始日開始">
                      <Icon type="question-circle-o" />
                    </Tooltip>
                  </span>
                }
              >
                {getFieldDecorator('serviceBeginDate', {
                  initialValue: null,
                  rules: [
                    {
                      type: 'object',
                      message: '請輸入有效起始日期',
                    },
                  ],
                })(<DatePicker placeholder="請選擇索拉服務起始日期" />)}
              </FormItem>
              <FormItem
                {...formItemLayout}
                label={
                  <span>
                    租金固定費用(年)&nbsp;
                    <Tooltip title="屋主每年所收取之固定租賃費用, ex: 70,000, 若無則填0">
                      <Icon type="question-circle-o" />
                    </Tooltip>
                  </span>
                }
              >
                {getFieldDecorator('rentYearCost', {
                  initialValue: null,
                  rules: [
                    {
                      type: 'number',
                      message: '請輸入租金固定費用(年)',
                    },
                  ],
                })(<InputNumber style={{ width: '200px' }} min={0} placeholder="70,000" />)}
                <span className="ant-form-text"> 元</span>
              </FormItem>
              <FormItem
                {...formItemLayout}
                label={
                  <span>
                    租金浮動費率&nbsp;
                    <Tooltip title="由電費提撥部分比例，做為屋主所收取之租賃費用, ex: 0.08, 若無則填0">
                      <Icon type="question-circle-o" />
                    </Tooltip>
                  </span>
                }
              >
                {getFieldDecorator('rentRateByIncome', {
                  initialValue: null,
                  rules: [
                    {
                      type: 'number',
                      message: '請輸入租金浮動費率',
                    },
                  ],
                })(<InputNumber style={{ width: '200px' }} min={0} placeholder="0.08" />)}
              </FormItem>
              <FormItem
                {...formItemLayout}
                label={
                  <span>
                    租金浮動計費基礎&nbsp;
                    <Tooltip title="租金浮動計費基礎, 通常法人屋主以含稅電費計算、自然人屋主以未稅電費計算, 視正式合約而定">
                      <Icon type="question-circle-o" />
                    </Tooltip>
                  </span>
                }
              >
                {getFieldDecorator('rentWithTax', {
                  initialValue: null,
                  rules: [
                    {
                      type: 'boolean',
                      message: '請選擇租金浮動計費基礎',
                    },
                  ],
                })(
                  <Select placeholder="請選擇租金浮動計費基礎">
                    {withTaxList.map((v, i) => (
                      <Option key={i} value={v.value}>
                        {v.name}
                      </Option>
                    ))}
                  </Select>
                )}
              </FormItem>
              <FormItem
                {...formItemLayout}
                label={
                  <span>
                    租金浮動計費負擔&nbsp;
                    <Tooltip title="租金浮動計費基礎, 通常含稅電費須扣電表租費、未稅電費不需扣電表租費計算, 視正式合約而定">
                      <Icon type="question-circle-o" />
                    </Tooltip>
                  </span>
                }
              >
                {getFieldDecorator('rentWithMeter', {
                  initialValue: null,
                  rules: [
                    {
                      type: 'boolean',
                      message: '請選擇租金浮動計費負擔',
                    },
                  ],
                })(
                  <Select placeholder="請選擇租金浮動計費負擔">
                    {withMeterList.map((v, i) => (
                      <Option key={i} value={v.value}>
                        {v.name}
                      </Option>
                    ))}
                  </Select>
                )}
              </FormItem>
              <FormItem {...tailFormItemLayout}>
                <Button type="primary" htmlType="submit">
                  建立
                </Button>
              </FormItem>
            </Form>
            <Modal
              title={this.state.modalTitle}
              visible={this.state.visible}
              onCancel={this.handleCancel}
              footer={footer}
            >
              {this.state.modalData}
            </Modal>
          </Card>
        </Content>
      </div>
    );
  }
}

const WrappedCreatePvForm = Form.create()(CreatePvForm);

export default WrappedCreatePvForm;
