import React, { Component } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import key from '../config/key';
import { numberFormat } from './Utils/Format';
import { Layout, Card, Tooltip } from 'antd';
const { Header, Content } = Layout;

const io = require('socket.io-client');

class PowerMonitor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      socket: io(key.websocketURL),
      list: [],
      updateTime: null
    };
  }

  componentDidMount() {
    this.state.socket.on('error', function(data) {
      console.log('socket on error...', data);
    });

    this.state.socket.on('connect', () => {
      console.log('socket connect to %s', key.websocketURL);

      this.state.socket.emit('initList', data => {
        this.setState(data);
      });
    });

    this.state.socket.on('disconnect', () => console.log('disconnect'));

    this.state.socket.on('updateList', data => {
      this.setState(data);
    });
  }

  componentWillUnmount() {
    this.state.socket.disconnect();
  }

  render() {
    this.state.list.sort((a, b) => {
      if (a.lastDate === null) {
        return -1;
      } else if (b.lastDate === null) {
        return 1;
      } else if (moment(a.lastDate).format('YYYY-MM-DD') < moment(b.lastDate).format('YYYY-MM-DD')) {
        return -1;
      } else if (moment(a.lastDate).format('YYYY-MM-DD') > moment(b.lastDate).format('YYYY-MM-DD')) {
        return 1;
      } else if (a.unitValue < b.unitValue) {
        return -1;
      } else if (a.unitValue > b.unitValue) {
        return 1;
      } else {
        return 0;
      }
    });

    let list = [];
    let noDataCount = 0,
      noTodayCount = 0,
      noUpdateCount = 0,
      normalCount = 0;
    for (let i = 0; i < this.state.list.length; i++) {
      let d = this.state.list[i];
      let value;
      if (d.lastDate === null) {
        noDataCount += 1;
        value = (
          <Tooltip title={'最後發電時間: 無'}>
            <NoData>尚無資料</NoData>
          </Tooltip>
        );
      } else if (d.todayValue === null) {
        noTodayCount += 1;
        value = (
          <Tooltip title={'最後更新時間: ' + d.lastDate}>
            <NoToday>今日未發電</NoToday>
          </Tooltip>
        );
      } else if (moment(this.state.updateTime).diff(d.lastDate, 'minutes') >= 30) {
        noUpdateCount += 1;
        value = (
          <Tooltip title={'最後更新時間: ' + d.lastDate}>
            <NoUpdate>
              {numberFormat({ value: parseFloat(d.unitValue).toFixed(2), postfix: '度/KW' })}
            </NoUpdate>
          </Tooltip>
        );
      } else {
        normalCount += 1;
        value = (
          <Tooltip title={'最後更新時間: ' + d.lastDate}>
            <Normal>{numberFormat({ value: parseFloat(d.unitValue).toFixed(2), postfix: '度/KW' })}</Normal>
          </Tooltip>
        );
      }
      list.push(
        <Block key={i}>
          <Tooltip title={d.sntwId + '_' + d.name}>
            <Id>{d.projectCode}：</Id>
          </Tooltip>
          {value}
        </Block>
      );
    }

    return (
      <div>
        <Header
          style={{
            background: '#fff',
            padding: 0,
            textAlign: 'center',
            fontSize: '2em'
          }}
        >
          本日發電監控
        </Header>
        <Content style={{ margin: '24px 16px 0', overflow: 'initial' }}>
          <Card style={{ width: '100%' }}>
            <Container>
              {'最後更新時間：' +
                moment(this.state.updateTime).format('YYYY-MM-DD HH:mm:ss') +
                '　已掛表案場總數：' +
                this.state.list.length +
                '　無資料：' +
                noDataCount +
                '　今日未發電：' +
                noTodayCount +
                '　更新異常：' +
                noUpdateCount +
                '　正常發電：' +
                normalCount}
            </Container>
            <Container>{list}</Container>
          </Card>
        </Content>
      </div>
    );
  }
}

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Block = styled.div`
  margin: 10px 10px 0 0;
  padding: 3px;
  background-color: Gainsboro;
  display: flex;
`;

const Id = styled.div`
  width: 50px;
`;

const Value = styled.div`
  width: 80px;
  text-align: right;
  font-weight: bold;
`;

const NoData = styled(Value)`
  color: grey;
`;

const NoToday = styled(Value)`
  color: red;
`;

const NoUpdate = styled(Value)`
  color: darkorange;
`;

const Normal = styled(Value)`
  color: green;
`;

export default PowerMonitor;
