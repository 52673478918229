export const setPagination = (pagination) => {
  sessionStorage.setItem('pagination', JSON.stringify(pagination));
};

export const getPagination = () => {
  const pagination = JSON.parse(sessionStorage.getItem('pagination'));
  return pagination ? pagination : {};
};

export const setProfile = (name, profile) => {
  sessionStorage.setItem('profile_' + name, JSON.stringify(profile));
};

export const getProfile = (name) => {
  return JSON.parse(sessionStorage.getItem('profile_' + name));
};

export const getToken = () => {
  const token = sessionStorage.getItem('token');
  return token || '';
};

export const getRole = () => {
  const role = sessionStorage.getItem('role');
  return role || '';
};
