import React, { Component } from 'react';
import axios from 'axios';
import { Table, Modal } from 'antd';

import { numberFormat } from '../Utils/Format';
import { getToken } from '../../utils/setting';

class Holding extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataSource: [],
    };
  }

  componentDidMount() {
    this.loadData();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.changed) {
      this.loadData();
    }
  }

  loadData() {
    return axios
      .get(`/api/spv/holdingForSola/${this.props.id}`, { headers: { Authorization: getToken() } })
      .then((res) => {
        if (res.data.status) {
          this.setState({ dataSource: res.data.result.holding });
        }
      })
      .catch((error) => {
        Modal.error({
          title: '錯誤訊息',
          content: error.response.data.msg || '很抱歉, 系統發生錯誤, 請稍後重試或聯絡系統管理員',
        });
      });
  }

  render() {
    const padding = 32;
    const fontSize = 14;
    const columns = [
      {
        title: '代碼',
        dataIndex: 'pvId',
        key: 'pvId',
        width: padding + fontSize * 5,
      },
      {
        title: '索拉名稱',
        dataIndex: 'solaName',
        key: 'solaName',
        width: padding + fontSize * 12,
      },
      {
        title: '結算起始日',
        dataIndex: 'validityDateBegin',
        key: 'validityDateBegin',
        width: padding + fontSize * 8,
      },
      {
        title: '掛表日',
        dataIndex: 'meterBeginDate',
        key: 'meterBeginDate',
        width: padding + fontSize * 8,
      },
      {
        title: '總發行量',
        dataIndex: 'totalAmount',
        key: 'totalAmount',
        width: padding + fontSize * 8,
        render: (v) => numberFormat({ value: v }),
      },
      {
        title: '持有數量',
        dataIndex: 'amount',
        key: 'amount',
        width: padding + fontSize * 8,
        render: (v) => numberFormat({ value: v }),
      },
      {
        title: '持有比例',
        dataIndex: 'percentage',
        key: 'percentage',
        width: padding + fontSize * 5,
        render: (_, record) =>
          Number(record.amount / record.totalAmount).toLocaleString(undefined, {
            style: 'percent',
            minimumSignificantDigits: 1,
            maximumSignificantDigits: 4,
          }),
      },
      {
        title: '持有容量(kWp)_估算',
        dataIndex: 'holdingCapacity',
        key: 'holdingCapacity',
        width: padding + fontSize * 8,
        render: (_, record) =>
          Number((record.capacity * record.amount) / record.totalAmount).toLocaleString(undefined, {
            minimumSignificantDigits: 1,
            maximumSignificantDigits: 6,
          }),
      },
      {
        title: '總容量(kWp)',
        dataIndex: 'capacity',
        key: 'capacity',
        width: padding + fontSize * 5,
        render: (v) => numberFormat({ value: v }),
      },
    ];
    return (
      <div>
        <Table dataSource={this.state.dataSource} columns={columns} pagination={false} />
      </div>
    );
  }
}

export default Holding;
