import React from 'react';
import { Layout, Card } from 'antd';
const { Header, Content } = Layout;

const PageNotFound = () => {
  return (
    <div>
      <Header
        style={{
          background: '#fff',
          padding: 0,
          textAlign: 'center',
          fontSize: '2em',
        }}
      >
        查無此頁
      </Header>
      <Content style={{ margin: '24px 16px 0', overflow: 'initial' }}>
        <Card style={{ width: '100%' }}>Page Not Found</Card>
      </Content>
    </div>
  );
};

export default PageNotFound;
