import React, { Component } from 'react';
import axios from 'axios';
import { Table, Modal } from 'antd';

import valueToName from '../Definition/ValueToName';
import stageList from '../Definition/StageList';
import statusList from '../Definition/StatusList';
import withTaxList from '../Definition/WithTaxList';
import withMeterList from '../Definition/WithMeterList';
import decayList from '../Definition/DecayList';
import { numberFormat, percentFormat } from '../Utils/Format';
import { getToken } from '../../utils/setting';

class PvInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.changed) {
      this.loadData();
    }
  }

  loadData() {
    return axios
      .get(`/api/pv/maintainForSola/${this.props.id}`, { headers: { Authorization: getToken() } })
      .then((res) => {
        if (res.data.status) {
          this.setState({ data: res.data.result.pv });
        } else {
          Modal.error({
            title: '錯誤訊息',
            content: res.data.msg,
          });
        }
      })
      .catch((error) => {
        Modal.error({
          title: '錯誤訊息',
          content: error.response.data.msg || '很抱歉, 系統發生錯誤, 請稍後重試或聯絡系統管理員',
        });
      });
  }

  render() {
    const d = this.state.data;
    if (d === null) {
      return <div>No data</div>;
    }

    const padding = 32;
    const fontSize = 14;
    const columns = [
      {
        title: '項目',
        dataIndex: 'colName',
        width: padding + fontSize * 8,
        key: 'colName',
      },
      {
        title: '資料值',
        dataIndex: 'colValue',
        key: 'colValue',
      },
    ];

    let dataSource = [];
    dataSource.push({
      key: 'id',
      colName: '索拉代碼',
      colValue: d.id,
    });
    dataSource.push({
      key: 'solaName',
      colName: '索拉名稱',
      colValue: d.solaName,
    });
    dataSource.push({
      key: 'name',
      colName: '電廠名稱',
      colValue: d.name,
    });
    dataSource.push({
      key: 'stage',
      colName: '建置階段',
      colValue: valueToName(stageList, d.stage),
    });
    dataSource.push({
      key: 'status',
      colName: '狀態',
      colValue: valueToName(statusList, d.status),
    });
    dataSource.push({
      key: 'projectCode',
      colName: '專案代碼',
      colValue: d.projectCode,
    });
    dataSource.push({
      key: 'owner',
      colName: '電廠擁有者',
      colValue: d.owner,
    });
    dataSource.push({
      key: 'location',
      colName: '電廠地址',
      colValue: d.location,
    });
    dataSource.push({
      key: 'coordLat',
      colName: 'GPS緯度',
      colValue: d.coordLat,
    });
    dataSource.push({
      key: 'coordLng',
      colName: 'GPS經度',
      colValue: d.coordLng,
    });
    dataSource.push({
      key: 'capacity',
      colName: '裝置容量',
      colValue: numberFormat({ value: d.capacity, postfix: 'kWp' }),
    });
    dataSource.push({
      key: 'decay',
      colName: '發電年衰減率',
      colValue: valueToName(decayList, d.decay),
    });
    dataSource.push({
      key: 'annualAmount',
      colName: '保證發電量',
      colValue: numberFormat({ value: d.annualAmount, postfix: 'kWp' }),
    });
    dataSource.push({
      key: 'constructionCost',
      colName: '單位成本',
      colValue: numberFormat({ value: d.constructionCost, postfix: '元' }),
    });
    dataSource.push({
      key: 'totalCapital',
      colName: '總成本',
      colValue: numberFormat({ value: d.totalCapital, postfix: '元' }),
    });
    dataSource.push({
      key: 'ownedCapital',
      colName: '理論投資金額',
      colValue: numberFormat({ value: d.ownedCapital, postfix: '元' }),
    });
    dataSource.push({
      key: 'validityDateBegin',
      colName: '結算起始日',
      colValue: d.validityDateBegin,
    });
    dataSource.push({
      key: 'validityDuration',
      colName: '結算年限',
      colValue: numberFormat({ value: d.validityDuration, postfix: '年' }),
    });
    dataSource.push({
      key: 'meterBeginDate',
      colName: '掛表日',
      colValue: d.meterBeginDate,
    });
    dataSource.push({
      key: 'sellPrice',
      colName: '躉售電價',
      colValue: numberFormat({ value: d.sellPrice, postfix: '元' }),
    });
    dataSource.push({
      key: 'operationRateByCapital',
      colName: '營運固定費率',
      colValue: percentFormat(d.operationRateByCapital),
    });
    dataSource.push({
      key: 'operationRateByIncome',
      colName: '營運浮動費率',
      colValue: percentFormat(d.operationRateByIncome),
    });
    dataSource.push({
      key: 'serviceRateByIncome',
      colName: '索拉服務費率',
      colValue: percentFormat(d.serviceRateByIncome),
    });
    dataSource.push({
      key: 'serviceBeginDate',
      colName: '索拉服務起始日',
      colValue: d.serviceBeginDate ? d.serviceBeginDate : '結算起始日',
    });
    dataSource.push({
      key: 'rentYearCost',
      colName: '租金固定費用(年)',
      colValue: numberFormat({ value: d.rentYearCost, postfix: '元' }),
    });
    dataSource.push({
      key: 'rentRateByIncome',
      colName: '租金浮動費率',
      colValue: percentFormat(d.rentRateByIncome),
    });
    dataSource.push({
      key: 'rentWithTax',
      colName: '浮動租金計費基礎',
      colValue: valueToName(withTaxList, d.rentWithTax),
    });
    dataSource.push({
      key: 'rentWithMeter',
      colName: '浮動租金計費負擔',
      colValue: valueToName(withMeterList, d.rentWithMeter),
    });

    return (
      <div>
        <Table dataSource={dataSource} columns={columns} pagination={false} />
      </div>
    );
  }
}

export default PvInfo;
