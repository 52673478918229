import React, { Component } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { Table, Modal } from 'antd';

import { numberFormat } from '../Utils/Format';
import { getToken } from '../../utils/setting';

class PvHolding extends Component {
  constructor(props) {
    super(props);

    this.state = {
      capacity: 0,
      amount: {
        total: 0,
        dispatched: 0,
        remained: 0,
      },
      dataSource: [],
    };
  }

  componentDidMount() {
    this.loadCapacity();
    this.loadAmount();
    this.loadData();
  }

  loadCapacity() {
    return axios
      .get(`/api/pv/capacityForSola/${this.props.id}`, { headers: { Authorization: getToken() } })
      .then((res) => {
        if (res.data.status && res.data.result.capacity.length === 1) {
          this.setState(res.data.result.capacity[0]);
        }
      })
      .catch((error) => {
        Modal.error({
          title: '錯誤訊息',
          content: error.response.data.msg || '很抱歉, 系統發生錯誤, 請稍後重試或聯絡系統管理員',
        });
      });
  }

  loadAmount() {
    return axios
      .get(`/api/pv/amountForSola/${this.props.id}`, { headers: { Authorization: getToken() } })
      .then((res) => {
        if (res.data.status) {
          let { amount } = this.state;
          if (res.data.result.amount.length === 1) {
            amount.total = res.data.result.amount[0].value;
            amount.dispatched = 0;
            amount.remained = res.data.result.amount[0].value;
          } else if (res.data.result.amount.length === 2) {
            amount.total = res.data.result.amount[0].value;
            amount.dispatched = res.data.result.amount[1].value;
            amount.remained = amount.total - amount.dispatched;
          }

          this.setState({ amount: amount });
        }
      })
      .catch((error) => {
        Modal.error({
          title: '錯誤訊息',
          content: error.response.data.msg || '很抱歉, 系統發生錯誤, 請稍後重試或聯絡系統管理員',
        });
      });
  }

  loadData() {
    return axios
      .get(`/api/pv/holderForSola/${this.props.id}`, { headers: { Authorization: getToken() } })
      .then((res) => {
        if (res.data.status) {
          this.setState({ dataSource: res.data.result.holder });
        }
      })
      .catch((error) => {
        Modal.error({
          title: '錯誤訊息',
          content: error.response.data.msg || '很抱歉, 系統發生錯誤, 請稍後重試或聯絡系統管理員',
        });
      });
  }

  render() {
    const { capacity } = this.state;
    const padding = 32;
    const fontSize = 14;
    const columns = [
      {
        title: '受益者',
        dataIndex: 'name',
        width: padding + fontSize * 4,
        key: 'name',
      },
      {
        title: '持有數量',
        dataIndex: 'amount',
        width: padding + fontSize * 4,
        key: 'amount',
        render: (v) => numberFormat({ value: v }),
      },
      {
        title: '持有百分比',
        dataIndex: 'percent',
        width: padding + fontSize * 4,
        key: 'percent',
        render: (text, record) =>
          Number(record.amount / record.totalAmount).toLocaleString(undefined, {
            style: 'percent',
            minimumSignificantDigits: 1,
            maximumSignificantDigits: 4,
          }),
      },
      {
        title: '持有容量(kWp)_推算',
        dataIndex: 'holdingCapacity',
        key: 'holdingCapacity',
        width: padding + fontSize * 8,
        render: (_, record) =>
          Number((capacity * record.amount) / record.totalAmount).toLocaleString(undefined, {
            minimumSignificantDigits: 1,
            maximumSignificantDigits: 6,
          }),
      },
    ];

    const totalWidth = columns.reduce((total, elem) => {
      return total + elem.width;
    }, 0);

    return (
      <div>
        <div style={{ marginBottom: '1em' }}>
          <Amount>總發行量: {numberFormat({ value: this.state.amount.total })}</Amount>
          <Amount>已分配: {numberFormat({ value: this.state.amount.dispatched })}</Amount>
          <Amount>剩餘量: {numberFormat({ value: this.state.amount.remained })}</Amount>
        </div>
        <div style={{ marginBottom: '1em' }}>
          <Amount>總容量: {numberFormat({ value: this.state.capacity, postfix: 'kWp' })}</Amount>
          <Amount>
            已分配:{' '}
            {numberFormat({
              value: (this.state.capacity * this.state.amount.dispatched) / this.state.amount.total,
              postfix: 'kWp',
            })}
          </Amount>
          <Amount>
            剩餘量:{' '}
            {numberFormat({
              value: (this.state.capacity * this.state.amount.remained) / this.state.amount.total,
              postfix: 'kWp',
            })}
          </Amount>
        </div>
        <Table
          dataSource={this.state.dataSource}
          columns={columns}
          pagination={false}
          scroll={{ x: totalWidth }}
        />
      </div>
    );
  }
}

const Amount = styled.span`
  margin-left: 20px;
`;

export default PvHolding;
