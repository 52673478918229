import React, { Component } from 'react';
import {
  Form,
  Input,
  Select,
  Button,
  InputNumber,
  DatePicker,
  Modal
} from 'antd';
const FormItem = Form.Item;
const Option = Select.Option;

class EditDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: true
    };
  }

  handleCancel = () => {
    this.setState({
      visible: false
    });
    setTimeout(() => this.props.onHide(), 500);
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onNewValue(values);
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    const formItemLayout = {
      key: 1,
      labelCol: { span: 6 },
      wrapperCol: { span: 14 }
    };

    const tailFormItemLayout = {
      key: 2,
      wrapperCol: {
        span: 8,
        offset: 6
      }
    };

    let formItem = [];
    switch (this.props.record.type) {
      case 'text':
        formItem.push(
          <FormItem
            {...formItemLayout}
            label={<span>{this.props.record.colName}&nbsp;</span>}
          >
            {getFieldDecorator(this.props.record.key, {
              initialValue: null,
              rules: [
                {
                  required: this.props.record.required,
                  message: '必須有值'
                }
              ]
            })(<Input placeholder={this.props.record.colValue} />)}
          </FormItem>
        );
        break;
      case 'number':
      case 'float':
        formItem.push(
          <FormItem
            {...formItemLayout}
            label={<span>{this.props.record.colName}&nbsp;</span>}
          >
            {getFieldDecorator(this.props.record.key, {
              initialValue: null,
              rules: [
                {
                  type: this.props.record.type,
                  required: this.props.record.required,
                  message: '必須有值'
                }
              ]
            })(
              <InputNumber
                style={{ width: '200px' }}
                min={0}
                placeholder={this.props.record.realValue}
              />
            )}
          </FormItem>
        );
        break;
      case 'selection':
        formItem.push(
          <FormItem
            {...formItemLayout}
            label={<span>{this.props.record.colName}&nbsp;</span>}
          >
            {getFieldDecorator(this.props.record.key, {
              initialValue: this.props.record.realValue,
              rules: [
                {
                  required: this.props.record.required,
                  message: '必須有值'
                }
              ]
            })(
              <Select placeholder="請選擇">
                {this.props.record.items.map((v, i) => (
                  <Option key={i} value={v.value}>
                    {v.name}
                  </Option>
                ))}
              </Select>
            )}
          </FormItem>
        );
        break;
      case 'date':
        formItem.push(
          <FormItem
            {...formItemLayout}
            label={<span>{this.props.record.colName}&nbsp;</span>}
          >
            {getFieldDecorator(this.props.record.key, {
              initialValue: null,
              rules: [
                {
                  required: this.props.record.required,
                  message: '必須有值'
                }
              ]
            })(<DatePicker placeholder={this.props.record.colValue} />)}
          </FormItem>
        );
        break;
      default:
        break;
    }
    formItem.push(
      <FormItem {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit">
          確定
        </Button>
      </FormItem>
    );

    return (
      <div>
        {this.props.record ? (
          <Modal
            title="編輯"
            visible={this.state.visible}
            onCancel={this.handleCancel}
            footer={null}
          >
            <Form onSubmit={this.handleSubmit}>{formItem}</Form>
          </Modal>
        ) : null}
      </div>
    );
  }
}

const WrappedEditDialogForm = Form.create()(EditDialog);

export default WrappedEditDialogForm;
