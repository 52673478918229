import { useState } from 'react';
import { Container } from './index';

const GetInitUser = () => {
  const account = sessionStorage.getItem('account');
  const role = sessionStorage.getItem('role');
  const token = sessionStorage.getItem('token');

  if (account && role && token) {
    return { account, role, token };
  } else {
    return null;
  }
};

const useAuth = () => {
  const [user, setUser] = useState(GetInitUser());

  const AuthUser = (authUser) => {
    if (authUser) {
      authUser.token = 'Bearer ' + authUser.token;

      sessionStorage.setItem('account', authUser.account);
      sessionStorage.setItem('role', authUser.role);
      sessionStorage.setItem('token', authUser.token);
      setUser(authUser);
    }
  };
  const UnAuthUser = () => {
    sessionStorage.removeItem('account');
    sessionStorage.removeItem('role');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('pagination');
    setUser(null);
  };

  return { user, AuthUser, UnAuthUser };
};
export default Container(useAuth); //remenber use Container to wrap
