import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment';
import { Table, Button, Icon, Modal } from 'antd';
import EditDialog from '../Utils/EditDialog';

import valueToName from '../Definition/ValueToName';
import stageList from '../Definition/StageList';
import statusList from '../Definition/StatusList';
import withTaxList from '../Definition/WithTaxList';
import withMeterList from '../Definition/WithMeterList';
import decayList from '../Definition/DecayList';
import { numberFormat, percentFormat } from '../Utils/Format';
import { getToken } from '../../utils/setting';

class PvInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      showEditor: false,
      editorRecord: null,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.changed) {
      this.loadData();
    }
  }

  loadData() {
    return axios
      .get(`/api/pv/maintainForCheck/${this.props.id}`, { headers: { Authorization: getToken() } })
      .then((res) => {
        if (res.data.status) {
          this.setState({ data: res.data.result.pv });
        } else {
          Modal.error({
            title: '錯誤訊息',
            content: res.data.msg,
          });
        }
      })
      .catch((error) => {
        Modal.error({
          title: '錯誤訊息',
          content: error.response.data.msg || '很抱歉, 系統發生錯誤, 請稍後重試或聯絡系統管理員',
        });
      });
  }

  handleEdit = (record) => {
    this.setState({
      showEditor: true,
      editorRecord: record,
    });
  };

  handleNewValue = (v) => {
    const { key } = this.state.editorRecord;
    axios
      .post(
        `/api/pv/editForCheck`,
        {
          id: this.props.id,
          field: key,
          value: moment.isMoment(v[key]) ? v[key].format('YYYY-MM-DD') : v[key],
        },
        { headers: { Authorization: getToken() } }
      )
      .then((res) => {
        if (res.data.status) {
          let { data } = this.state;
          if (moment.isMoment(v[key])) {
            data[key] = v[key].format('YYYY-MM-DD');
          } else {
            data[key] = v[key];
          }
          this.setState({ data: data, showEditor: false });
        } else {
          Modal.error({
            title: '錯誤訊息',
            content: res.data.msg,
          });
        }
      })
      .catch((error) => {
        Modal.error({
          title: '錯誤訊息',
          content: error.response.data.msg || '很抱歉, 系統發生錯誤, 請稍後重試或聯絡系統管理員',
        });
      });
  };

  handleCheck = (record) => {
    const { key } = record;
    axios
      .post(
        `/api/pv/check`,
        {
          id: this.props.id,
          field: key,
        },
        { headers: { Authorization: getToken() } }
      )
      .then((res) => {
        if (res.data.status) {
          let { data } = this.state;
          data[`${key}C`] = moment().format('YYYY-MM-DD HH:mm:ss');
          this.setState({ data: data });
        } else {
          Modal.error({
            title: '錯誤訊息',
            content: res.data.msg,
          });
        }
      })
      .catch((error) => {
        Modal.error({
          title: '錯誤訊息',
          content: error.response.data.msg || '很抱歉, 系統發生錯誤, 請稍後重試或聯絡系統管理員',
        });
      });
  };

  handleReject = (record) => {
    const { key } = record;
    axios
      .post(
        `/api/pv/reject`,
        {
          id: this.props.id,
          field: key,
        },
        { headers: { Authorization: getToken() } }
      )
      .then((res) => {
        if (res.data.status) {
          let { data } = this.state;
          data[`${key}R`] = moment().format('YYYY-MM-DD HH:mm:ss');
          this.setState({ data: data });
        } else {
          Modal.error({
            title: '錯誤訊息',
            content: res.data.msg,
          });
        }
      })
      .catch((error) => {
        Modal.error({
          title: '錯誤訊息',
          content: error.response.data.msg || '很抱歉, 系統發生錯誤, 請稍後重試或聯絡系統管理員',
        });
      });
  };

  handleSync = (record) => {
    //TODO:檢查索拉代碼&名稱
    const { key } = record;
    axios
      .post(
        `/api/pv/sync`,
        {
          id: this.props.id,
          field: key,
        },
        { headers: { Authorization: getToken() } }
      )
      .then((res) => {
        if (res.data.status) {
          let { data } = this.state;
          data[`${key}S`] = moment().format('YYYY-MM-DD HH:mm:ss');
          this.setState({ data: data });
        } else {
          Modal.error({
            title: '錯誤訊息',
            content: res.data.msg,
          });
        }
      })
      .catch((error) => {
        Modal.error({
          title: '錯誤訊息',
          content: error.response.data.msg || '很抱歉, 系統發生錯誤, 請稍後重試或聯絡系統管理員',
        });
      });
  };

  render() {
    const d = this.state.data;
    if (d === null) {
      return <div>No data</div>;
    }

    const padding = 32;
    const fontSize = 14;
    const columns = [
      {
        title: '項目',
        dataIndex: 'colName',
        width: padding + fontSize * 8,
        key: 'colName',
      },
      {
        title: '資料值',
        dataIndex: 'colValue',
        key: 'colValue',
      },
      {
        title: '審核狀態',
        key: 'checkStatus',
        width: padding + fontSize * 4,
        render: (text, record) => {
          if (record.timeReject) {
            if (record.timeCheck) {
              if (record.timeReject > record.timeCheck && record.timeReject > record.timeUpdate) {
                return <div style={{ color: 'red' }}>否決中</div>;
              }
            } else if (record.timeReject > record.timeUpdate) {
              return <div style={{ color: 'red' }}>否決中</div>;
            }
          }

          return null;
        },
      },
      {
        title: '操作',
        key: 'action',
        render: (text, record) => {
          if (record.editable !== undefined) {
            if (record.editable)
              return (
                <Button onClick={() => this.handleEdit(record)}>
                  <Icon type="edit" />
                  編輯
                </Button>
              );
            else {
              return null;
            }
          }

          if (this.props.forceRejectMode) {
            if (!(record.timeUpdate <= record.timeReject)) {
              return (
                <Button onClick={() => this.handleReject(record)}>
                  <Icon type="close" />
                  否決
                </Button>
              );
            } else {
              return null;
            }
          } else if (!(record.timeUpdate <= record.timeCheck) && !(record.timeUpdate <= record.timeReject)) {
            return (
              <div>
                <Button onClick={() => this.handleCheck(record)}>
                  <Icon type="check" />
                  核准
                </Button>
                <Button onClick={() => this.handleReject(record)}>
                  <Icon type="close" />
                  否決
                </Button>
              </div>
            );
          } else if (
            record.timeCheck &&
            !(record.timeCheck <= record.timeReject) &&
            !(record.timeSync >= record.timeCheck)
          ) {
            return (
              <Button onClick={() => this.handleSync(record)}>
                <Icon type="sync" />
                同步
              </Button>
            );
          } else {
            return null;
          }
        },
      },
      {
        title: '確認依據',
        dataIndex: 'desc',
        key: 'desc',
        render: (text, record) => {
          if (record.key === 'meterBeginDate') {
            return [
              text,
              <span style={{ color: 'red' }} key={0}>
                {' '}
                (掛表日有值時，其他項目也必須有值)
              </span>,
            ];
          } else {
            return text;
          }
        },
      },
    ];

    let dataSource = [];
    dataSource.push({
      key: 'sntwId',
      colName: '索拉代碼',
      colValue: d.sntwId,
      realValue: d.sntwId,
      editable: d.sntwId === null ? true : false,
      required: true,
      type: 'text',
    });
    dataSource.push({
      key: 'solaName',
      colName: '索拉名稱',
      colValue: d.solaName,
      realValue: d.solaName,
      editable: d.solaName === null ? true : false,
      required: true,
      type: 'text',
    });
    dataSource.push({
      key: 'name',
      colName: '電廠名稱',
      colValue: d.name,
      realValue: d.name,
      timeUpdate: d.nameU,
      timeCheck: d.nameC,
      timeSync: d.nameS,
      timeReject: d.nameR,
      type: 'text',
    });
    dataSource.push({
      key: 'stage',
      colName: '建置階段',
      colValue: valueToName(stageList, d.stage),
      realValue: d.stage,
      timeUpdate: d.stageU,
      timeCheck: d.stageC,
      timeSync: d.stageS,
      timeReject: d.stageR,
      type: 'selection',
      items: stageList,
    });
    dataSource.push({
      key: 'status',
      colName: '狀態',
      colValue: valueToName(statusList, d.status),
      realValue: d.status,
      timeUpdate: d.statusU,
      timeCheck: d.statusC,
      timeSync: d.statusS,
      timeReject: d.statusR,
      type: 'selection',
      items: statusList,
    });
    dataSource.push({
      key: 'projectCode',
      colName: '專案代碼',
      colValue: d.projectCode,
      realValue: d.projectCode,
      timeUpdate: d.projectCodeU,
      timeCheck: d.projectCodeC,
      timeSync: d.projectCodeS,
      timeReject: d.projectCodeR,
      type: 'text',
    });
    dataSource.push({
      key: 'owner',
      colName: '電廠擁有者',
      colValue: d.owner,
      realValue: d.owner,
      timeUpdate: d.ownerU,
      timeCheck: d.ownerC,
      timeSync: d.ownerS,
      timeReject: d.ownerR,
      desc: '共同開發合作協議書',
      type: 'text',
    });
    dataSource.push({
      key: 'location',
      colName: '電廠地址',
      colValue: d.location,
      realValue: d.location,
      timeUpdate: d.locationU,
      timeCheck: d.locationC,
      timeSync: d.locationS,
      timeReject: d.locationR,
      desc: '租約',
      type: 'text',
    });
    dataSource.push({
      key: 'coordLat',
      colName: 'GPS緯度',
      colValue: d.coordLat,
      realValue: d.coordLat,
      timeUpdate: d.coordLatU,
      timeCheck: d.coordLatC,
      timeSync: d.coordLatS,
      timeReject: d.coordLatR,
      desc: '服務建議書',
      type: 'number',
    });
    dataSource.push({
      key: 'coordLng',
      colName: 'GPS經度',
      colValue: d.coordLng,
      realValue: d.coordLng,
      timeUpdate: d.coordLngU,
      timeCheck: d.coordLngC,
      timeSync: d.coordLngS,
      timeReject: d.coordLngR,
      desc: '服務建議書',
      type: 'number',
    });
    dataSource.push({
      key: 'capacity',
      colName: '裝置容量',
      colValue: numberFormat({ value: d.capacity, postfix: 'kWp' }),
      realValue: d.capacity,
      timeUpdate: d.capacityU,
      timeCheck: d.capacityC,
      timeSync: d.capacityS,
      timeReject: d.capacityR,
      desc: '併聯試運轉函',
      type: 'number',
    });
    dataSource.push({
      key: 'decay',
      colName: '發電年衰減率',
      colValue: valueToName(decayList, d.decay),
      realValue: d.decay,
      timeUpdate: d.decayU,
      timeCheck: d.decayC,
      timeSync: d.decayS,
      timeReject: d.decayR,
      desc: '服務建議書',
      type: 'selection',
      items: decayList,
    });
    dataSource.push({
      key: 'annualAmount',
      colName: '保證發電量',
      colValue: numberFormat({ value: d.annualAmount, postfix: 'kWp' }),
      realValue: d.annualAmount,
      timeUpdate: d.annualAmountU,
      timeCheck: d.annualAmountC,
      timeSync: d.annualAmountS,
      timeReject: d.annualAmountR,
      desc: '服務建議書、運營維護服務合約書',
      type: 'number',
    });
    dataSource.push({
      key: 'constructionCost',
      colName: '單位成本',
      colValue: numberFormat({ value: d.constructionCost, postfix: '元' }),
      realValue: d.constructionCost,
      timeUpdate: d.constructionCostU,
      timeCheck: d.constructionCostC,
      timeSync: d.constructionCostS,
      timeReject: d.constructionCostR,
      desc: '服務建議書、共同開發合作協議書',
      type: 'number',
    });
    dataSource.push({
      key: 'totalCapital',
      colName: '總成本',
      colValue: numberFormat({ value: d.totalCapital, postfix: '元' }),
      realValue: d.totalCapital,
      timeUpdate: d.totalCapitalU,
      timeCheck: d.totalCapitalC,
      timeSync: d.totalCapitalS,
      timeReject: d.totalCapitalR,
      desc: '服務建議書、共同開發合作協議書',
      type: 'number',
    });
    dataSource.push({
      key: 'ownedCapital',
      colName: '理論投資金額',
      colValue: numberFormat({ value: d.ownedCapital, postfix: '元' }),
      realValue: d.ownedCapital,
      timeUpdate: d.ownedCapitalU,
      timeCheck: d.ownedCapitalC,
      timeSync: d.ownedCapitalS,
      timeReject: d.ownedCapitalR,
      desc: '服務建議書、共同開發合作協議書',
      type: 'number',
    });
    dataSource.push({
      key: 'validityDateBegin',
      colName: '結算起始日',
      colValue: d.validityDateBegin,
      realValue: d.validityDateBegin,
      timeUpdate: d.validityDateBeginU,
      timeCheck: d.validityDateBeginC,
      timeSync: d.validityDateBeginS,
      timeReject: d.validityDateBeginR,
      desc: '正式購售電能函',
      type: 'date',
    });
    dataSource.push({
      key: 'validityDuration',
      colName: '結算年限',
      colValue: numberFormat({ value: d.validityDuration, postfix: '年' }),
      realValue: d.validityDuration,
      timeUpdate: d.validityDurationU,
      timeCheck: d.validityDurationC,
      timeSync: d.validityDurationS,
      timeReject: d.validityDurationR,
      desc: '電能購售契約、正式購售電能函',
      type: 'number',
    });
    dataSource.push({
      key: 'meterBeginDate',
      colName: '掛表日',
      colValue: d.meterBeginDate,
      realValue: d.meterBeginDate,
      timeUpdate: d.meterBeginDateU,
      timeCheck: d.meterBeginDateC,
      timeSync: d.meterBeginDateS,
      timeReject: d.meterBeginDateR,
      desc: '併聯試運轉函',
      type: 'date',
    });
    dataSource.push({
      key: 'sellPrice',
      colName: '躉售電價',
      colValue: numberFormat({ value: d.sellPrice, postfix: '元' }),
      realValue: d.sellPrice,
      timeUpdate: d.sellPriceU,
      timeCheck: d.sellPriceC,
      timeSync: d.sellPriceS,
      timeReject: d.sellPriceR,
      desc: '服務建議書、正式購售電能函',
      type: 'number',
    });
    dataSource.push({
      key: 'operationRateByCapital',
      colName: '營運固定費率',
      colValue: percentFormat(d.operationRateByCapital),
      realValue: d.operationRateByCapital,
      timeUpdate: d.operationRateByCapitalU,
      timeCheck: d.operationRateByCapitalC,
      timeSync: d.operationRateByCapitalS,
      timeReject: d.operationRateByCapitalR,
      desc: '運營維護服務合約書',
      type: 'number',
    });
    dataSource.push({
      key: 'operationRateByIncome',
      colName: '營運浮動費率',
      colValue: percentFormat(d.operationRateByIncome),
      realValue: d.operationRateByIncome,
      timeUpdate: d.operationRateByIncomeU,
      timeCheck: d.operationRateByIncomeC,
      timeSync: d.operationRateByIncomeS,
      timeReject: d.operationRateByIncomeR,
      desc: '運營維護服務合約書',
      type: 'number',
    });
    dataSource.push({
      key: 'serviceRateByIncome',
      colName: '索拉服務費率',
      colValue: percentFormat(d.serviceRateByIncome),
      realValue: d.serviceRateByIncome,
      timeUpdate: d.serviceRateByIncomeU,
      timeCheck: d.serviceRateByIncomeC,
      timeSync: d.serviceRateByIncomeS,
      timeReject: d.serviceRateByIncomeR,
      desc: '光電案場資訊管理合約',
      type: 'number',
    });
    dataSource.push({
      key: 'serviceBeginDate',
      colName: '索拉服務起始日',
      colValue: d.serviceBeginDate ? d.serviceBeginDate : '結算起始日',
      realValue: d.serviceBeginDate,
      timeUpdate: d.serviceBeginDateU,
      timeCheck: d.serviceBeginDateC,
      timeSync: d.serviceBeginDateS,
      timeReject: d.serviceBeginDateR,
      desc: '光電案場資訊管理合約, Aily',
      type: 'date',
    });
    dataSource.push({
      key: 'rentYearCost',
      colName: '租金固定費用(年)',
      colValue: numberFormat({ value: d.rentYearCost, postfix: '元' }),
      realValue: d.rentYearCost,
      timeUpdate: d.rentYearCostU,
      timeCheck: d.rentYearCostC,
      timeSync: d.rentYearCostS,
      timeReject: d.rentYearCostR,
      desc: '租約、Aily',
      type: 'number',
    });
    dataSource.push({
      key: 'rentRateByIncome',
      colName: '租金浮動費率',
      colValue: percentFormat(d.rentRateByIncome),
      realValue: d.rentRateByIncome,
      timeUpdate: d.rentRateByIncomeU,
      timeCheck: d.rentRateByIncomeC,
      timeSync: d.rentRateByIncomeS,
      timeReject: d.rentRateByIncomeR,
      desc: '租約、Aily',
      type: 'number',
    });
    dataSource.push({
      key: 'rentWithTax',
      colName: '浮動租金計費基礎',
      colValue: valueToName(withTaxList, d.rentWithTax),
      realValue: d.rentWithTax,
      timeUpdate: d.rentWithTaxU,
      timeCheck: d.rentWithTaxC,
      timeSync: d.rentWithTaxS,
      timeReject: d.rentWithTaxR,
      desc: '租約、Aily',
      type: 'selection',
      items: withTaxList,
    });
    dataSource.push({
      key: 'rentWithMeter',
      colName: '浮動租金計費負擔',
      colValue: valueToName(withMeterList, d.rentWithMeter),
      realValue: d.rentWithMeter,
      timeUpdate: d.rentWithMeterU,
      timeCheck: d.rentWithMeterC,
      timeSync: d.rentWithMeterS,
      timeReject: d.rentWithMeterR,
      desc: '租約、Aily',
      type: 'selection',
      items: withMeterList,
    });

    return (
      <div>
        <Table dataSource={dataSource} columns={columns} pagination={false} />
        {this.state.showEditor ? (
          <EditDialog
            record={this.state.editorRecord}
            onNewValue={this.handleNewValue}
            onHide={() => this.setState({ showEditor: false })}
          />
        ) : null}
      </div>
    );
  }
}

export default PvInfo;
