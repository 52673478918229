import React, { Component } from 'react';
import { Modal, Button } from 'antd';

class ConfirmDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: true
    };
  }

  handleOk = e => {
    e.preventDefault();
    this.props.onConfirm();
  };

  handleCancel = () => {
    this.setState({
      visible: false
    });
    setTimeout(() => this.props.onHide(), 500);
  };

  render() {
    return (
      <div>
        <Modal
          title={this.props.title}
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <Button key="cancel" onClick={this.handleCancel}>
              取消
            </Button>,
            <Button key="ok" type="primary" onClick={this.handleOk}>
              確認
            </Button>
          ]}
        >
          <p>{this.props.msg}</p>
        </Modal>
      </div>
    );
  }
}

export default ConfirmDialog;
