import React, { Component } from 'react';
import moment from 'moment';
import { Form, Icon, DatePicker, InputNumber, Button, Modal, Tooltip } from 'antd';
const FormItem = Form.Item;

class InsuranceEditDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: true
    };
  }

  handleCancel = () => {
    this.setState({
      visible: false
    });
    setTimeout(() => this.props.onHide(), 500);
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onHandle(values);
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 14 }
    };

    const tailFormItemLayout = {
      wrapperCol: {
        span: 8,
        offset: 6
      }
    };

    return (
      <Modal title="修改保險單" visible={this.state.visible} onCancel={this.handleCancel} footer={null}>
        <Form onSubmit={this.handleSubmit}>
          <FormItem
            {...formItemLayout}
            label={
              <span>
                保險起始日&nbsp;
                <Tooltip title="請參考保險單上的保險期間，填入起始日">
                  <Icon type="question-circle-o" />
                </Tooltip>
              </span>
            }
          >
            {getFieldDecorator('beginDate', {
              initialValue: moment(this.props.record.beginDate)
            })(<DatePicker placeholder="保險起始日" disabled />)}
          </FormItem>
          <FormItem
            {...formItemLayout}
            label={
              <span>
                保險結束日&nbsp;
                <Tooltip title="請參考保險單上的保險期間，填入結束日">
                  <Icon type="question-circle-o" />
                </Tooltip>
              </span>
            }
          >
            {getFieldDecorator('endDate', {
              initialValue: moment(this.props.record.endDate)
            })(<DatePicker placeholder="保險結束日" />)}
          </FormItem>
          <FormItem
            {...formItemLayout}
            label={
              <span>
                保險費&nbsp;
                <Tooltip title="請參考保單上的保險費">
                  <Icon type="question-circle-o" />
                </Tooltip>
              </span>
            }
          >
            {getFieldDecorator('cost', {
              initialValue: this.props.record.cost,
              rules: [
                {
                  type: 'integer',
                  required: true,
                  message: '請輸入保險費'
                }
              ]
            })(<InputNumber style={{ width: '200px' }} min={1} placeholder="10000" />)}
            <span className="ant-form-text"> 元</span>
          </FormItem>
          <FormItem {...tailFormItemLayout}>
            <Button type="primary" htmlType="submit">
              確定
            </Button>
          </FormItem>
        </Form>
      </Modal>
    );
  }
}

const WrappedInsuranceEditDialogForm = Form.create()(InsuranceEditDialog);

export default WrappedInsuranceEditDialogForm;
