import React from 'react';
import { Row } from 'antd';

const JRow = (props) => {
  return (
    <Row type="flex" gutter={[16, 16]} {...props}>
      {props.children}
    </Row>
  );
};

export default JRow;
