import React, { Component } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Table, Button, Icon, Modal } from 'antd';

import { numberFormat } from '../Utils/Format';
import { getToken } from '../../utils/setting';

class PvInsurance extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataSource: [],
    };
  }

  componentDidMount() {
    this.loadData();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.changed) {
      this.loadData();
    }
  }

  loadData() {
    return axios
      .get(`/api/insurance/allForCheck/${this.props.id}`, { headers: { Authorization: getToken() } })
      .then((res) => {
        if (res.data.status) {
          this.setState({ dataSource: res.data.result.insurances });
        }
      })
      .catch((error) => {
        Modal.error({
          title: '錯誤訊息',
          content: error.response.data.msg || '很抱歉, 系統發生錯誤, 請稍後重試或聯絡系統管理員',
        });
      });
  }

  handleCheck = (index) => {
    axios
      .post(`/api/insurance/check`, { index }, { headers: { Authorization: getToken() } })
      .then((res) => {
        if (res.data.status) {
          this.loadData();
        } else {
          Modal.error({
            title: '錯誤訊息',
            content: res.data.msg,
          });
        }
      })
      .catch((error) => {
        Modal.error({
          title: '錯誤訊息',
          content: error.response.data.msg || '很抱歉, 系統發生錯誤, 請稍後重試或聯絡系統管理員',
        });
      });
  };

  handleReject = (index) => {
    axios
      .post(`/api/insurance/reject`, { index }, { headers: { Authorization: getToken() } })
      .then((res) => {
        if (res.data.status) {
          this.loadData();
        } else {
          Modal.error({
            title: '錯誤訊息',
            content: res.data.msg,
          });
        }
      })
      .catch((error) => {
        Modal.error({
          title: '錯誤訊息',
          content: error.response.data.msg || '很抱歉, 系統發生錯誤, 請稍後重試或聯絡系統管理員',
        });
      });
  };

  handleSync = (index) => {
    axios
      .post(`/api/insurance/sync`, { index }, { headers: { Authorization: getToken() } })
      .then((res) => {
        if (res.data.status) {
          this.loadData();
        } else {
          Modal.error({
            title: '錯誤訊息',
            content: res.data.msg,
          });
        }
      })
      .catch((error) => {
        Modal.error({
          title: '錯誤訊息',
          content: error.response.data.msg || '很抱歉, 系統發生錯誤, 請稍後重試或聯絡系統管理員',
        });
      });
  };

  render() {
    const padding = 32;
    const fontSize = 14;
    const columns = [
      {
        title: '保險起始日',
        dataIndex: 'beginDate',
        key: 'beginDate',
        width: padding + fontSize * 6,
        align: 'center',
      },
      {
        title: '保險結束日',
        dataIndex: 'endDate',
        key: 'endDate',
        width: padding + fontSize * 6,
        align: 'center',
      },
      {
        title: '保險費用',
        dataIndex: 'cost',
        key: 'cost',
        width: padding + fontSize * 6,
        align: 'right',
        render: (v) => numberFormat({ value: v, postfix: '元' }),
      },
      {
        title: '檔案',
        dataIndex: 'fileName',
        key: 'fileName',
        width: padding + fontSize * 6,
        align: 'left',
        render: (text, record) => {
          if (record.files) {
            return (
              <Container>
                {record.files.map((file, i) => (
                  <Item key={i}>
                    <Link to={`/api/upload/file/${file}`} target="_blank">
                      {`查看${i + 1}  `}
                    </Link>
                  </Item>
                ))}
              </Container>
            );
          } else {
            return null;
          }
        },
      },
      {
        title: '狀態',
        dataIndex: 'status',
        key: 'status',
        width: padding + fontSize * 3,
        align: 'center',
        render: (text, record) => (record.deleted ? '刪除' : ''),
      },
      {
        title: '審核狀態',
        key: 'checkStatus',
        width: padding + fontSize * 4,
        render: (text, record) => {
          if (record.rejectTime) {
            if (record.checkTime) {
              if (record.rejectTime > record.checkTime && record.rejectTime > record.updateTime) {
                return <div style={{ color: 'red' }}>否決中</div>;
              }
            } else if (record.rejectTime > record.updateTime) {
              return <div style={{ color: 'red' }}>否決中</div>;
            }
          }

          return null;
        },
      },
      {
        title: '操作',
        key: 'action',
        width: padding + fontSize * 3,
        align: 'center',
        render: (text, record) => {
          if (this.props.forceRejectMode) {
            if (!(record.updateTime <= record.rejectTime)) {
              return (
                <Button onClick={() => this.handleReject(record.index)}>
                  <Icon type="close" />
                  否決
                </Button>
              );
            } else {
              return null;
            }
          } else if (!(record.updateTime <= record.checkTime) && !(record.updateTime <= record.rejectTime)) {
            return (
              <div>
                <Button onClick={() => this.handleCheck(record.index)}>
                  <Icon type="check" />
                  核准
                </Button>
                <Button onClick={() => this.handleReject(record.index)}>
                  <Icon type="close" />
                  否決
                </Button>
              </div>
            );
          } else if (
            record.checkTime &&
            !(record.checkTime <= record.rejectTime) &&
            !(record.syncTime >= record.checkTime)
          ) {
            return (
              <Button onClick={() => this.handleSync(record.index)}>
                <Icon type="sync" />
                同步
              </Button>
            );
          } else {
            return null;
          }
        },
      },
    ];
    return (
      <div>
        <Table dataSource={this.state.dataSource} columns={columns} />
      </div>
    );
  }
}

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Item = styled.div`
  position: relative;
  width: 4em;
`;

export default PvInsurance;
