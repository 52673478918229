import React, { Component } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Table, Modal, Empty } from 'antd';
import fileItemList from '../Definition/FileItemList';
import { getToken } from '../../utils/setting';

class PvFile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.changed) {
      this.loadData();
    }
  }

  loadData() {
    return axios
      .get(`/api/file/forSola/${this.props.id}`, { headers: { Authorization: getToken() } })
      .then((res) => {
        if (res.data.status) {
          if (res.data.result.pvFile.length > 0) {
            this.setState({ data: res.data.result.pvFile[0] });
          }
        } else {
          Modal.error({
            title: '錯誤訊息',
            content: res.data.msg,
          });
        }
      })
      .catch((error) => {
        Modal.error({
          title: '錯誤訊息',
          content: error.response.data.msg || '很抱歉, 系統發生錯誤, 請稍後重試或聯絡系統管理員',
        });
      });
  }

  render() {
    const d = this.state.data;
    if (d === null) {
      return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
    }

    const padding = 32;
    const fontSize = 14;
    const columns = [
      {
        title: '項目',
        dataIndex: 'itemName',
        width: padding + fontSize * 10,
        key: 'itemName',
      },
      {
        title: '取得時間',
        dataIndex: 'getTime',
        width: padding + fontSize * 8,
        key: 'getTime',
      },
      {
        title: '檔案',
        dataIndex: 'files',
        key: 'files',
        width: padding + fontSize * 16,
        align: 'left',
        render: (list, record) => {
          if (list) {
            return (
              <Container>
                {list.map((file, i) => (
                  <Item key={i}>
                    <Link to={`/api/upload/file/${file}`} target="_blank">
                      {`查看${i + 1}  `}
                    </Link>
                  </Item>
                ))}
              </Container>
            );
          } else {
            return null;
          }
        },
      },
    ];

    const dataSource = fileItemList.map((v) => ({
      key: v.name,
      itemName: v.name,
      getTime: d.items[v.name] ? d.items[v.name].getTime : null,
      files: d.items[v.name] ? d.items[v.name].files : null,
    }));

    return (
      <div>
        <Table dataSource={dataSource} columns={columns} pagination={false} />
      </div>
    );
  }
}

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Item = styled.div`
  position: relative;
  margin-right: 1em;
  width: 4em;
`;

export default PvFile;
