import React, { Component } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Modal, Empty } from 'antd';
import { getToken } from '../../utils/setting';

class PvImage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.changed) {
      this.loadData();
    }
  }

  loadData() {
    return axios
      .get(`/api/image/forSola/${this.props.id}`, { headers: { Authorization: getToken() } })
      .then((res) => {
        if (res.data.status) {
          if (res.data.result.pvImage.length > 0) {
            this.setState({ data: res.data.result.pvImage[0] });
          }
        } else {
          Modal.error({
            title: '錯誤訊息',
            content: res.data.msg,
          });
        }
      })
      .catch((error) => {
        Modal.error({
          title: '錯誤訊息',
          content: error.response.data.msg || '很抱歉, 系統發生錯誤, 請稍後重試或聯絡系統管理員',
        });
      });
  }

  render() {
    const { data } = this.state;
    if (data) {
      const images = data.files.map((image, i) => (
        <Item key={i}>
          <Link to={`/api/upload/file/${image}`} target="_blank">
            <Img src={`/api/upload/file/${image}`} alt="" />
          </Link>
        </Item>
      ));

      return <Container>{images}</Container>;
    } else {
      return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
    }
  }
}

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Item = styled.div`
  position: relative;
  margin-top: 10px;
  margin-right: 10px;
  width: 300px;
  height: 300px;
  border: 1px solid lightgrey;
  padding: 25px 0 15px 0;
`;

const Img = styled.img`
  display: block;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
`;
export default PvImage;
