import React, { Component } from 'react';
import axios from 'axios';

import { numberFormat } from '../Utils/Format';
import { numberSort, stringSort } from '../Utils/Sort';
import { getPagination, setPagination, getToken } from '../../utils/setting';
import SettlementViewDialog from './SettlementViewDialog';

import { Layout, Table, Card, Input, Button, Icon, message } from 'antd';
const { Header, Content } = Layout;

class BillSettlement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      dataSource: [],
      repay: [],
      compensation: [],
      pagination: getPagination(),
      record: null,
      showView: false,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    (async () => {
      await this.loadRepay();
      await this.loadCompensation();
      await this.loadBill();
    })();
  };

  loadRepay = async () => {
    const res = await axios.get('/api/pvAccount/repay', { headers: { Authorization: getToken() } });
    if (res.data.status) {
      const { repay } = res.data.result;
      this.setState({ repay });
      //console.log('repay', repay);
    }
  };

  loadCompensation = async () => {
    const res = await axios.get('/api/pvAccount/compensation', { headers: { Authorization: getToken() } });
    if (res.data.status) {
      const { compensation } = res.data.result;
      this.setState({ compensation });
      //console.log('compensation', compensation);
    }
  };

  loadBill = async () => {
    const res = await axios.get(`/api/billSettlement`, { headers: { Authorization: getToken() } });
    if (res.data.status) {
      const { billSettlement } = res.data.result;
      billSettlement.forEach((elm) => {
        elm.profit =
          elm.currentProfit + elm.lastAccumulation > 0 ? elm.currentProfit + elm.lastAccumulation : 0;
        elm.otherProfit = 0;
        elm.otherProfitList = [];

        const compensation = this.state.compensation.filter(
          (e) => e.pvId === elm.pvId && e.userId === elm.userId && e.settlementMonth === elm.settlementMonth
        );
        compensation.forEach((e) => {
          elm.profit += e.profit;
          elm.otherProfit += e.profit;
          e.key = elm.otherProfitList.length;
          elm.otherProfitList.push(e);
        });

        const repay = this.state.repay.filter(
          (e) => e.pvId === elm.pvId && e.userId === elm.userId && e.settlementMonth === elm.settlementMonth
        );
        repay.forEach((e) => {
          elm.profit += e.profit;
          elm.otherProfit += e.profit;
          e.key = elm.otherProfitList.length;
          if (e.comment) {
            e.reason += e.comment;
          }
          elm.otherProfitList.push(e);
        });
      });

      //console.log('billSettlement', billSettlement);

      this.setState({
        dataSource: billSettlement,
        loaded: true,
      });
    } else {
      this.setState({ loaded: true });
    }
  };

  getColumeProps = (columnName, dataIndex, sortMethod, sortField, sortOrder, searchable, filters) => {
    let props = {
      title: columnName,
      dataIndex: dataIndex,
      key: dataIndex,
      sorter: (a, b) => sortMethod(a[dataIndex], b[dataIndex]),
      sortOrder: sortField === dataIndex ? sortOrder : false,
    };

    if (searchable) {
      props = Object.assign(props, this.getColumnSearchProps(columnName, dataIndex, filters || {}));
    }
    return props;
  };

  getColumnSearchProps = (columnName, dataIndex, filters) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`搜尋 ${columnName}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          搜尋
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          重置
        </Button>
      </div>
    ),
    filterIcon: (filtered) => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : false,
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    filteredValue: filters[dataIndex],
  });

  onChange = (pagination, filters, sorter) => {
    const paginationInfo = {
      current: pagination.current,
      sortOrder: sorter.order,
      sortField: sorter.field,
      filters: filters,
    };
    setPagination(paginationInfo);
    this.setState({ pagination: paginationInfo });
  };

  handleSearch = (selectedKeys, confirm) => {
    confirm();
  };

  handleReset = (clearFilters) => {
    clearFilters();
  };

  handleOpenView = (record) => {
    this.setState({
      showView: true,
      record,
    });
  };

  handleUpdate = (v) => {
    return axios
      .post(
        `/api/billSettlement/update`,
        {
          settlementMonth: this.state.record.settlementMonth,
          pvId: this.state.record.pvId,
          userId: this.state.record.userId,
          requestDate: v.requestDate ? v.requestDate.format('YYYY-MM-DD') : null,
        },
        { headers: { Authorization: getToken() } }
      )
      .then((res) => {
        if (res.data.status) {
          this.setState({ showView: false, record: null });
          this.loadData();
          message.info('更新成功');
        } else {
          message.error(res.data.msg);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  render() {
    const { sortField, sortOrder, filters } = this.state.pagination;

    const padding = 32;
    const fontSize = 14;
    const columns = [
      {
        ...this.getColumeProps(
          '結算月份',
          'settlementMonth',
          stringSort,
          sortField,
          sortOrder,
          true,
          filters
        ),
        width: padding + fontSize * 5,
        fixed: 'left',
      },
      {
        ...this.getColumeProps('營運公司', 'owner', stringSort, sortField, sortOrder, true, filters),
        width: padding + fontSize * 10,
        fixed: 'left',
      },
      {
        ...this.getColumeProps('電廠代碼', 'projectCode', stringSort, sortField, sortOrder, true, filters),
        width: padding + fontSize * 5,
        fixed: 'left',
      },
      {
        ...this.getColumeProps('電廠名稱', 'name', stringSort, sortField, sortOrder, true, filters),
        width: padding + fontSize * 10,
      },
      {
        ...this.getColumeProps('電費起始日', 'begin', stringSort, sortField, sortOrder),
        width: padding + fontSize * 7,
      },
      {
        ...this.getColumeProps('電費結束日', 'end', stringSort, sortField, sortOrder),
        width: padding + fontSize * 7,
      },
      {
        ...this.getColumeProps('受益者', 'beneficiary', stringSort, sortField, sortOrder, true, filters),
        width: padding + fontSize * 10,
      },
      {
        ...this.getColumeProps('應得收益', 'currentProfit', numberSort, sortField, sortOrder),
        width: padding + fontSize * 7,
        align: 'right',
        render: (v) => numberFormat({ value: v, postfix: '元' }),
      },
      {
        ...this.getColumeProps('上期損益', 'lastAccumulation', numberSort, sortField, sortOrder),
        width: padding + fontSize * 7,
        align: 'right',
        render: (v) => numberFormat({ value: v, postfix: '元' }),
      },
      {
        ...this.getColumeProps('其他帳務', 'otherProfit', numberSort, sortField, sortOrder),
        width: padding + fontSize * 7,
        align: 'right',
        render: (v) => numberFormat({ value: v, postfix: '元' }),
      },
      {
        ...this.getColumeProps('應請金額', 'profit', numberSort, sortField, sortOrder),
        width: padding + fontSize * 7,
        align: 'right',
        render: (v) => numberFormat({ value: v, postfix: '元' }),
      },
      {
        ...this.getColumeProps('請款日期', 'requestDate', stringSort, sortField, sortOrder, true, filters),
        width: padding + fontSize * 7,
      },
      {
        title: '操作',
        key: 'action',
        width: padding + fontSize * 4,
        fixed: 'right',
        render: (text, record) => {
          return <Button onClick={() => this.handleOpenView(record)}>查看</Button>;
        },
      },
    ];

    const totalWidth = columns.reduce((total, elem) => {
      return total + elem.width;
    }, 0);

    return (
      <div>
        <Header
          style={{
            background: '#fff',
            padding: 0,
            textAlign: 'center',
            fontSize: '2em',
          }}
        >
          查詢結算資料
        </Header>
        <Content style={{ margin: '24px 16px 0', overflow: 'initial' }}>
          <Card style={{ width: '100%' }}>
            <Table
              dataSource={this.state.dataSource}
              columns={columns}
              defaultPageSize={5}
              pagination={this.state.pagination}
              scroll={{ x: totalWidth }}
              onChange={this.onChange}
            />
          </Card>
        </Content>
        {this.state.showView ? (
          <SettlementViewDialog
            data={this.state.record}
            onHandle={this.handleUpdate}
            onHide={() => this.setState({ showView: false, record: null })}
          />
        ) : null}
      </div>
    );
  }
}

export default BillSettlement;
