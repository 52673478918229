import React, { Component } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Table, Button, Icon, Modal, Upload } from 'antd';

import { numberFormat } from '../Utils/Format';
import LoanFeeCreateDialog from './LoanFeeCreateDialog';
import LoanFeeEditDialog from './LoanFeeEditDialog';
import ConfirmDialog from '../Utils/ConfirmDialog';
import { getToken } from '../../utils/setting';

class PvLoanFee extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataSource: [],
      showCreater: false,
      record: null,
      showEditor: false,
      showDelete: false,
      showDeleteFile: false,
      file: null,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.changed) {
      this.loadData();
    }
  }

  loadData() {
    return axios
      .get(`/api/loanFee/all/${this.props.id}`, { headers: { Authorization: getToken() } })
      .then((res) => {
        if (res.data.status) {
          this.setState({ dataSource: res.data.result.loanFees });
        }
      })
      .catch((error) => {
        Modal.error({
          title: '錯誤訊息',
          content: error.response.data.msg || '很抱歉, 系統發生錯誤, 請稍後重試或聯絡系統管理員',
        });
      });
  }

  handleOpenCreater = () => {
    this.setState({ showCreater: true });
  };

  handleCreate = (v) => {
    return axios
      .post(
        `/api/loanFee/create`,
        {
          id: this.props.id,
          beginDate: v.beginDate.format('YYYY-MM-DD'),
          duration: v.duration,
          cost: v.cost,
        },
        { headers: { Authorization: getToken() } }
      )
      .then((res) => {
        if (res.data.status) {
          this.setState({ showCreater: false });
          this.loadData();
        } else {
          Modal.error({
            title: '錯誤訊息',
            content: res.data.msg,
          });
        }
      })
      .catch((error) => {
        Modal.error({
          title: '錯誤訊息',
          content: error.response.data.msg || '很抱歉, 系統發生錯誤, 請稍後重試或聯絡系統管理員',
        });
      });
  };

  handleUploadChange = (info, record) => {
    if (info.file.status === 'done') {
      axios
        .post(
          `/api/loanFee/uploadFile`,
          {
            index: record.index,
            file: info.file.response.result.file,
          },
          { headers: { Authorization: getToken() } }
        )
        .then((res) => {
          if (res.data.status) {
            this.loadData();
          } else {
            Modal.error({
              title: '錯誤訊息',
              content: res.data.msg,
            });
          }
        })
        .catch((error) => {
          Modal.error({
            title: '錯誤訊息',
            content: error.response.data.msg || '很抱歉, 系統發生錯誤, 請稍後重試或聯絡系統管理員',
          });
        });
    }
  };

  handleOpenEditor = (record) => {
    this.setState({ showEditor: true, record });
  };

  handleEditor = (v) => {
    return axios
      .post(
        `/api/loanFee/edit`,
        {
          index: this.state.record.index,
          beginDate: v.beginDate.format('YYYY-MM-DD'),
          duration: v.duration,
          cost: v.cost,
          fileName: v.fileName,
        },
        { headers: { Authorization: getToken() } }
      )
      .then((res) => {
        if (res.data.status) {
          this.setState({ showEditor: false, record: null });
          this.loadData();
        } else {
          Modal.error({
            title: '錯誤訊息',
            content: res.data.msg,
          });
        }
      })
      .catch((error) => {
        Modal.error({
          title: '錯誤訊息',
          content: error.response.data.msg || '很抱歉, 系統發生錯誤, 請稍後重試或聯絡系統管理員',
        });
      });
  };

  handleOpenDelete = (record) => {
    this.setState({
      showDelete: true,
      record,
    });
  };

  handleDelete = () => {
    axios
      .post(
        `/api/loanFee/delete`,
        {
          index: this.state.record.index,
        },
        { headers: { Authorization: getToken() } }
      )
      .then((res) => {
        if (res.data.status) {
          this.setState({ showDelete: false, record: null });
          this.loadData();
        } else {
          Modal.error({
            title: '錯誤訊息',
            content: res.data.msg,
          });
        }
      })
      .catch((error) => {
        Modal.error({
          title: '錯誤訊息',
          content: error.response.data.msg || '很抱歉, 系統發生錯誤, 請稍後重試或聯絡系統管理員',
        });
      });
  };

  handleOpenDeleteFile = (record, file) => {
    this.setState({
      showDeleteFile: true,
      record,
      file,
    });
  };

  handleDeleteFile = () => {
    axios
      .post(
        `/api/loanFee/deleteFile`,
        {
          index: this.state.record.index,
          file: this.state.file,
        },
        { headers: { Authorization: getToken() } }
      )
      .then((res) => {
        if (res.data.status) {
          this.setState({ showDeleteFile: false, record: null, file: null });
          this.loadData();
        } else {
          Modal.error({
            title: '錯誤訊息',
            content: res.data.msg,
          });
        }
      })
      .catch((error) => {
        Modal.error({
          title: '錯誤訊息',
          content: error.response.data.msg || '很抱歉, 系統發生錯誤, 請稍後重試或聯絡系統管理員',
        });
      });
  };

  render() {
    const padding = 32;
    const fontSize = 14;
    const columns = [
      {
        title: '手續費起始日',
        dataIndex: 'beginDate',
        key: 'beginDate',
        width: padding + fontSize * 6,
        align: 'center',
      },
      {
        title: '手續費攤提年限',
        dataIndex: 'duration',
        key: 'duration',
        width: padding + fontSize * 6,
        align: 'center',
        render: (v) => numberFormat({ value: v, postfix: '年' }),
      },
      {
        title: '手續費用',
        dataIndex: 'cost',
        key: 'cost',
        width: padding + fontSize * 6,
        align: 'right',
        render: (v) => numberFormat({ value: v, postfix: '元' }),
      },
      {
        title: '檔案',
        dataIndex: 'files',
        key: 'files',
        width: padding + fontSize * 6,
        align: 'left',
        render: (text, record) => {
          if (record.files) {
            return (
              <Container>
                {record.files.map((file, i) => (
                  <Item key={i}>
                    <Link to={`/api/upload/file/${file}`} target="_blank">
                      {`查看${i + 1}`}
                    </Link>
                    <Del onClick={() => this.handleOpenDeleteFile(record, file)}>
                      <Icon type="close-circle" />
                    </Del>
                  </Item>
                ))}
              </Container>
            );
          } else {
            return null;
          }
        },
      },
      {
        title: '審核狀態',
        key: 'checkStatus',
        width: padding + fontSize * 4,
        align: 'center',
        render: (text, record) => {
          if (record.rejectTime >= record.updateTime) {
            return <div style={{ color: 'red' }}>被否決</div>;
          } else if (!(record.checkTime >= record.updateTime)) {
            return '待核准';
          } else {
            return null;
          }
        },
      },
      {
        title: '操作',
        key: 'action',
        width: padding + fontSize * 12,
        align: 'center',
        render: (text, record) => {
          return (
            <span>
              <Upload
                name="file"
                showUploadList={false}
                action="/api/upload"
                headers={{ authorization: getToken() }}
                onChange={(info) => this.handleUploadChange(info, record)}
              >
                <Button>
                  <Icon type="upload" /> 上傳檔案
                </Button>
              </Upload>
              <Button onClick={() => this.handleOpenEditor(record)}>
                <Icon type="edit" />
                編輯
              </Button>
              <Button onClick={() => this.handleOpenDelete(record)}>
                <Icon type="delete" />
                刪除
              </Button>
            </span>
          );
        },
      },
    ];
    return (
      <div>
        <div style={{ marginBottom: '1em' }}>
          <Button onClick={this.handleOpenCreater}>
            <Icon type="edit" />
            新建手續費
          </Button>
        </div>
        <Table dataSource={this.state.dataSource} columns={columns} />
        {this.state.showCreater ? (
          <LoanFeeCreateDialog
            onHandle={this.handleCreate}
            onHide={() => this.setState({ showCreater: false })}
          />
        ) : null}
        {this.state.showEditor ? (
          <LoanFeeEditDialog
            record={this.state.record}
            onHandle={this.handleEditor}
            onHide={() => this.setState({ showEditor: false, record: null })}
          />
        ) : null}
        {this.state.showDelete ? (
          <ConfirmDialog
            title={'刪除'}
            msg={`確認刪除 ${this.state.record.beginDate} 的手續費`}
            onConfirm={this.handleDelete}
            onHide={() => this.setState({ showDelete: false, record: null })}
          />
        ) : null}
        {this.state.showDeleteFile ? (
          <ConfirmDialog
            title={'刪除'}
            msg={`確認刪除 ${this.state.record.beginDate} 手續費的檔案`}
            onConfirm={this.handleDeleteFile}
            onHide={() => this.setState({ showDeleteFile: false, record: null })}
          />
        ) : null}
      </div>
    );
  }
}

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Item = styled.div`
  position: relative;
  margin-right: 1em;
  width: 4em;
`;

const Del = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 100;
  color: red;
  cursor: pointer;
`;

export default PvLoanFee;
