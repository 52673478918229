import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Upload, Icon, message, Modal, Button, Empty } from 'antd';
import ConfirmDialog from '../Utils/ConfirmDialog';
import { getToken } from '../../utils/setting';

function beforeUpload(file) {
  const isImage = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isImage) {
    message.error('只限上傳 JPG or PNG 圖檔');
    return false;
  }

  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('圖檔必須小於 2MB!');
    return false;
  }
  return true;
}

class PvImage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      imageList: [],
      updateTime: null,
      checkTime: null,
      rejectTime: null,
      loading: false,
      showDelete: false,
      file: null,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.changed) {
      this.loadData();
    }
  }

  loadData() {
    return axios
      .get(`/api/image/${this.props.id}`, { headers: { Authorization: getToken() } })
      .then((res) => {
        if (res.data.status) {
          const { files, updateTime, checkTime, rejectTime } = res.data.result.pvImage;
          this.setState({ imageList: files, updateTime, checkTime, rejectTime });
        } else {
          Modal.error({
            title: '錯誤訊息',
            content: res.data.msg,
          });
        }
      })
      .catch((error) => {
        Modal.error({
          title: '錯誤訊息',
          content: error.response.data.msg || '很抱歉, 系統發生錯誤, 請稍後重試或聯絡系統管理員',
        });
      });
  }

  handleUploadChange = (info) => {
    if (info.file.status === 'uploading') {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === 'done') {
      axios
        .post(
          `/api/image/uploadFile`,
          {
            id: this.props.id,
            file: info.file.response.result.file,
          },
          { headers: { Authorization: getToken() } }
        )
        .then((res) => {
          this.setState({ loading: false });
          if (res.data.status) {
            let { imageList } = this.state;
            imageList.push(info.file.response.result.file);
            this.setState({ imageList, updateTime: moment().format('YYYY-MM-DD HH:mm:ss') });
          } else {
            Modal.error({
              title: '錯誤訊息',
              content: res.data.msg,
            });
          }
        })
        .catch((error) => {
          this.setState({ loading: false });
          Modal.error({
            title: '錯誤訊息',
            content: error.response.data.msg || '很抱歉, 系統發生錯誤, 請稍後重試或聯絡系統管理員',
          });
        });
    }
  };

  handleOpenDelete = (file) => {
    this.setState({
      showDelete: true,
      file,
    });
  };

  handleDelete = () => {
    axios
      .post(
        `/api/image/deleteFile`,
        {
          id: this.props.id,
          file: this.state.file,
        },
        { headers: { Authorization: getToken() } }
      )
      .then((res) => {
        if (res.data.status) {
          let { imageList, file } = this.state;
          const index = imageList.indexOf(file);
          imageList.splice(index, 1);
          this.setState({
            imageList,
            updateTime: moment().format('YYYY-MM-DD HH:mm:ss'),
            showDelete: false,
            file: null,
          });
        } else {
          Modal.error({
            title: '錯誤訊息',
            content: res.data.msg,
          });
        }
      })
      .catch((error) => {
        Modal.error({
          title: '錯誤訊息',
          content: error.response.data.msg || '很抱歉, 系統發生錯誤, 請稍後重試或聯絡系統管理員',
        });
      });
  };

  render() {
    const uploadButton = (
      <Button>
        <Icon type={this.state.loading ? 'loading' : 'upload'} />
        上傳
      </Button>
    );

    const { imageList } = this.state;
    const images = imageList.map((image, i) => (
      <Item key={i}>
        <Link to={`/api/upload/file/${image}`} target="_blank">
          <Img src={`/api/upload/file/${image}`} alt="" />
        </Link>
        <Del onClick={() => this.handleOpenDelete(image)}>
          <Icon type="delete" />
        </Del>
      </Item>
    ));

    let status = '無';
    if (this.state.updateTime) {
      if (this.state.rejectTime >= this.state.updateTime) {
        status = <span style={{ color: 'red' }}>被否決</span>;
      } else if (!(this.state.checkTime >= this.state.updateTime)) {
        status = '待核准';
      } else {
        status = '已核准';
      }
    }

    return (
      <div>
        <span>
          <Upload
            name="file"
            showUploadList={false}
            action="/api/upload"
            headers={{ authorization: getToken() }}
            beforeUpload={beforeUpload}
            onChange={this.handleUploadChange}
          >
            {uploadButton}
          </Upload>
        </span>
        <span style={{ padding: '1em' }}>審核狀態: {status}</span>
        {images.length > 0 ? <Container>{images}</Container> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
        {this.state.showDelete ? (
          <ConfirmDialog
            title={'刪除圖檔'}
            msg={'確認刪除此圖檔'}
            onConfirm={this.handleDelete}
            onHide={() => this.setState({ showDelete: false, file: null })}
          />
        ) : null}
      </div>
    );
  }
}

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Item = styled.div`
  position: relative;
  margin-top: 10px;
  margin-right: 10px;
  width: 300px;
  height: 300px;
  border: 1px solid lightgrey;
  padding: 25px 0 15px 0;
`;

const Img = styled.img`
  display: block;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
`;

const Del = styled.div`
  position: absolute;
  top: -5px;
  right: 0px;
  z-index: 100;
  font-size: 20px;
  color: grey;
  cursor: pointer;
`;

export default PvImage;
