import React from 'react';
import { Layout, Card } from 'antd';
const { Header, Content } = Layout;

const Home = () => {
  return (
    <div>
      <Header
        style={{
          background: '#fff',
          padding: 0,
          textAlign: 'center',
          fontSize: '2em'
        }}
      >
        Dashboard
      </Header>
      <Content style={{ margin: '24px 16px 0', overflow: 'initial' }}>
        <Card style={{ width: '100%' }}>Comming Soon!</Card>
      </Content>
    </div>
  );
};

export default Home;
