import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Table, Button, Icon, message, Modal } from 'antd';
import BillCreateDialog from './BillCreateDialog';
import ConfirmDialog from '../Utils/ConfirmDialog';
import { numberFormat } from '../Utils/Format';
import { getToken } from '../../utils/setting';

class PvBill extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sellPrice: null,
      dataSource: [],
      showCreater: false,
      showDelete: false,
      record: null,
    };
  }

  componentDidMount() {
    this.loadSellPrice();
    this.loadData();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.changed) {
      this.loadSellPrice();
      this.loadData();
    }
  }

  loadSellPrice() {
    return axios
      .get(`/api/bill/sellPrice/${this.props.id}`, { headers: { Authorization: getToken() } })
      .then((res) => {
        if (res.data.status) {
          this.setState({ sellPrice: res.data.result.sellPrice });
        } else {
          message.error(res.data.msg);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  loadData() {
    return axios
      .get(`/api/bill/all/${this.props.id}`, { headers: { Authorization: getToken() } })
      .then((res) => {
        if (res.data.status) {
          this.setState({ dataSource: res.data.result.bills });
        } else {
          message.error(res.data.msg);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  handleOpenCreater = () => {
    this.setState({ showCreater: true });
  };

  handleCreate = (v) => {
    return axios
      .post(
        `/api/bill/create`,
        {
          id: this.props.id,
          rangeBegin: v.range[0].format('YYYY-MM-DD'),
          rangeEnd: v.range[1].format('YYYY-MM-DD'),
          totalPower: v.totalPower,
          powerAmount: v.powerAmount,
          deductAmount: (v.deductAmount ?? 0) === '' ? 0 : v.deductAmount ?? 0,
          salesTax: v.salesTax,
          incomeWithTax: v.incomeWithTax,
          powerRent: v.powerRent,
          makeupRent: (v.makeupRent ?? 0) === '' ? 0 : v.makeupRent ?? 0,
          meterRentCost: v.meterRentCost,
          file: v.upload[0].response.result.file,
        },
        { headers: { Authorization: getToken() } }
      )
      .then((res) => {
        if (res.data.status) {
          this.setState({ showCreater: false });
          message.info('新增電費單成功');
          this.loadData();
        } else {
          message.error(res.data.msg);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  handleOpenDelete = (record) => {
    this.setState({
      showDelete: true,
      record,
    });
  };

  handleDelete = () => {
    axios
      .post(
        `/api/bill/delete`,
        { index: this.state.record.index },
        { headers: { Authorization: getToken() } }
      )
      .then((res) => {
        if (res.data.status) {
          this.setState({ showDelete: false, record: null });
          this.loadData();
        } else {
          Modal.error({
            title: '錯誤訊息',
            content: res.data.msg,
          });
        }
      })
      .catch((error) => {
        Modal.error({
          title: '錯誤訊息',
          content: error.response.data.msg || '很抱歉, 系統發生錯誤, 請稍後重試或聯絡系統管理員',
        });
      });
  };

  render() {
    const padding = 32;
    const fontSize = 14;
    const columns = [
      {
        title: '檔案',
        dataIndex: 'file',
        key: 'file',
        width: padding + fontSize * 2,
        fixed: 'left',
        align: 'center',
        render: (text, record) => {
          if (record.file) {
            return (
              <Link to={`/api/upload/file/${record.file}`} target="_blank">
                查看
              </Link>
            );
          } else {
            return null;
          }
        },
      },
      {
        title: '計費起始日',
        dataIndex: 'rangeBegin',
        width: padding + fontSize * 6,
        fixed: 'left',
        key: 'rangeBillBegin',
      },
      {
        title: '計費結束日',
        dataIndex: 'rangeEnd',
        width: padding + fontSize * 6,
        fixed: 'left',
        key: 'rangeBillEnd',
        render: (v) => moment(v).subtract(1, 'days').format('YYYY-MM-DD'),
      },
      {
        title: '發電度數',
        dataIndex: 'totalPower',
        width: padding + fontSize * 5,
        key: 'totalPower',
        align: 'right',
        render: (v) => numberFormat({ value: v, postfix: '度' }),
      },
      {
        title: '發電金額',
        dataIndex: 'powerAmount',
        width: padding + fontSize * 5,
        key: 'powerAmount',
        align: 'right',
        render: (v) => numberFormat({ value: v, postfix: '元' }),
      },
      {
        title: '補付(扣)電費',
        dataIndex: 'deductPowerAmount',
        width: padding + fontSize * 6,
        key: 'deductAmount',
        align: 'right',
        render: (v) => numberFormat({ value: v, postfix: '元' }),
      },
      {
        title: '營業稅',
        dataIndex: 'salesTax',
        width: padding + fontSize * 6,
        key: 'salesTax',
        align: 'right',
        render: (v) => numberFormat({ value: v, postfix: '元' }),
      },
      {
        title: '總電費(含稅)',
        dataIndex: 'incomeWithTax',
        width: padding + fontSize * 6,
        key: 'incomeWithTax',
        align: 'right',
        render: (v) => numberFormat({ value: v, postfix: '元' }),
      },
      {
        title: '本期電表租費',
        dataIndex: 'powerRent',
        width: padding + fontSize * 6,
        key: 'powerRent',
        align: 'right',
        render: (v) => numberFormat({ value: v, postfix: '元' }),
      },
      {
        title: '電表租費收(退)',
        dataIndex: 'makeupPowerRent',
        width: padding + fontSize * 7,
        key: 'makeupRent',
        align: 'right',
        render: (v) => numberFormat({ value: v, postfix: '元' }),
      },
      {
        title: '電表租費',
        dataIndex: 'meterRentCost',
        width: padding + fontSize * 4,
        key: 'meterRentCost',
        align: 'right',
        render: (v) => numberFormat({ value: v, postfix: '元' }),
      },
      {
        title: '審核狀態',
        key: 'checkStatus',
        width: padding + fontSize * 4,
        fixed: 'right',
        align: 'center',
        render: (text, record) => {
          if (record.rejectTime >= record.updateTime) {
            return <div style={{ color: 'red' }}>被否決</div>;
          } else if (!(record.checkTime >= record.updateTime)) {
            return '待核准';
          } else {
            return null;
          }
        },
      },
      {
        title: '操作',
        key: 'action',
        width: padding + fontSize * 4,
        fixed: 'right',
        align: 'center',
        render: (text, record) => {
          return (
            <Button onClick={() => this.handleOpenDelete(record)}>
              <Icon type="delete" />
              刪除
            </Button>
          );
        },
      },
    ];

    const totalWidth = columns.reduce((total, elem) => {
      return total + elem.width;
    }, 0);
    return (
      <div>
        <div style={{ marginBottom: '1em' }}>
          <Button onClick={this.handleOpenCreater}>
            <Icon type="edit" />
            新建電費單
          </Button>
        </div>
        <Table
          dataSource={this.state.dataSource}
          columns={columns}
          defaultPageSize={5}
          scroll={{ x: totalWidth }}
        />
        {this.state.showCreater ? (
          <BillCreateDialog
            sellPrice={this.state.sellPrice}
            deductAmount={0}
            makeupRent={0}
            onHandle={this.handleCreate}
            onHide={() => this.setState({ showCreater: false })}
          />
        ) : null}
        {this.state.showDelete ? (
          <ConfirmDialog
            title={'刪除'}
            msg={`確認刪除 ${this.state.record.rangeBegin}~${this.state.record.rangeEnd} 的電費單`}
            onConfirm={this.handleDelete}
            onHide={() => this.setState({ showDelete: false, record: null })}
          />
        ) : null}
      </div>
    );
  }
}

export default PvBill;
