import React, { Component } from 'react';
import moment from 'moment';
import { Form, Icon, DatePicker, InputNumber, Button, Modal, Tooltip } from 'antd';
const FormItem = Form.Item;

class LoanFeeEditDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: true
    };
  }

  handleCancel = () => {
    this.setState({
      visible: false
    });
    setTimeout(() => this.props.onHide(), 500);
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onHandle(values);
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 14 }
    };

    const tailFormItemLayout = {
      wrapperCol: {
        span: 8,
        offset: 6
      }
    };

    return (
      <Modal title="修改手續費" visible={this.state.visible} onCancel={this.handleCancel} footer={null}>
        <Form onSubmit={this.handleSubmit}>
          <FormItem
            {...formItemLayout}
            label={
              <span>
                費用攤銷起始日&nbsp;
                <Tooltip title="請參考貸款的手續費攤銷方式，填入起始日">
                  <Icon type="question-circle-o" />
                </Tooltip>
              </span>
            }
          >
            {getFieldDecorator('beginDate', {
              initialValue: moment(this.props.record.beginDate)
            })(<DatePicker placeholder="攤銷起始日" disabled />)}
          </FormItem>
          <FormItem
            {...formItemLayout}
            label={
              <span>
                攤銷年限&nbsp;
                <Tooltip title="請參考貸款的手續費攤銷方式, 填入攤銷年限">
                  <Icon type="question-circle-o" />
                </Tooltip>
              </span>
            }
          >
            {getFieldDecorator('duration', {
              initialValue: this.props.record.duration,
              rules: [
                {
                  type: 'integer',
                  required: true,
                  message: '請輸入攤銷年限'
                }
              ]
            })(<InputNumber style={{ width: '200px' }} min={1} placeholder="5" />)}
            <span className="ant-form-text"> 年</span>
          </FormItem>
          <FormItem
            {...formItemLayout}
            label={
              <span>
                手續費&nbsp;
                <Tooltip title="請參考貸款所需的手續費">
                  <Icon type="question-circle-o" />
                </Tooltip>
              </span>
            }
          >
            {getFieldDecorator('cost', {
              initialValue: this.props.record.cost,
              rules: [
                {
                  type: 'integer',
                  required: true,
                  message: '請輸入手續費'
                }
              ]
            })(<InputNumber style={{ width: '200px' }} min={1} placeholder="10000" />)}
            <span className="ant-form-text"> 元</span>
          </FormItem>
          <FormItem {...tailFormItemLayout}>
            <Button type="primary" htmlType="submit">
              確定
            </Button>
          </FormItem>
        </Form>
      </Modal>
    );
  }
}

const WrappedLoanFeeEditDialogForm = Form.create()(LoanFeeEditDialog);

export default WrappedLoanFeeEditDialogForm;
