const stageList = [
  { value: 0, name: '0.尚未開始' },
  { value: 1, name: '1.已簽訂租約' },
  { value: 2, name: '2.台電併聯審查核可' },
  { value: 3, name: '3.取得同意備案函' },
  { value: 4, name: '4.簽訂電能購售契約' },
  { value: 5, name: '5.已併聯掛表' },
  { value: 6, name: '6.完成設備登記' },
  { value: 7, name: '7.開始正式售電' }
];

export default stageList;
