import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Layout, Table, Card, Modal } from 'antd';
import { getToken } from '../utils/setting';

const { Header, Content } = Layout;

class SpvQuery3 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataSource: [],
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    return axios
      .get(`/api/spv/listForSola`, { headers: { Authorization: getToken() } })
      .then((res) => {
        if (res.data.status) {
          this.setState({ dataSource: res.data.result.spvList });
        }
      })
      .catch((error) => {
        Modal.error({
          title: '錯誤訊息',
          content: error.response.data.msg || '很抱歉, 系統發生錯誤, 請稍後重試或聯絡系統管理員',
        });
      });
  }

  render() {
    const padding = 32;
    const fontSize = 14;
    const columns = [
      {
        title: '名稱',
        dataIndex: 'name',
        key: 'name',
        width: padding + fontSize * 10,
        fixed: 'left',
      },
      {
        title: '公司名稱',
        dataIndex: 'companyName',
        key: 'companyName',
        width: padding + fontSize * 18,
      },
      {
        title: '地址',
        dataIndex: 'address',
        key: 'address',
        width: padding + fontSize * 20,
      },
      {
        title: '出金帳戶',
        dataIndex: 'cashoutAccount',
        key: 'cashoutAccount',
        width: padding + fontSize * 30,
      },
      {
        title: '操作',
        key: 'action',
        fixed: 'right',
        width: padding + fontSize * 2,
        render: (text, record) => (
          <span>
            <Link to={`/SpvMaintain3/${record.id}`}>查看</Link>
          </span>
        ),
      },
    ];

    const totalWidth = columns.reduce((total, elem) => {
      return total + elem.width;
    }, 0);

    return (
      <div>
        <Header
          style={{
            background: '#fff',
            padding: 0,
            textAlign: 'center',
            fontSize: '2em',
          }}
        >
          查詢SPV資料
        </Header>
        <Content style={{ margin: '24px 16px 0', overflow: 'initial' }}>
          <Card style={{ width: '100%' }}>
            <Table
              dataSource={this.state.dataSource}
              columns={columns}
              pagination={false}
              scroll={{ x: totalWidth }}
            />
          </Card>
        </Content>
      </div>
    );
  }
}

export default SpvQuery3;
