import React, { Component } from 'react';
import moment from 'moment';
import { Form, Icon, DatePicker, InputNumber, Button, Modal, Tooltip, Upload, Radio, Row, Col } from 'antd';
import { getToken } from '../../utils/setting';

const FormItem = Form.Item;
const RangePicker = DatePicker.RangePicker;

class BillCreateDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: true,
    };
  }

  handleRangeBillChange = (rangeBill) => {
    console.log('rangeBill', rangeBill);
    this.props.form.setFieldsValue({
      range: [rangeBill[0], moment(rangeBill[1]).add(1, 'days')],
    });
  };

  handlePowerChange = (totalPower) => {
    const values = this.props.form.getFieldsValue(['sellPrice', 'deductAmount', 'tax']);

    this.props.form.setFieldsValue({
      powerAmount: Math.round(totalPower * values.sellPrice),
      salesTax: Math.round(
        (Math.round(totalPower * values.sellPrice) + (values.deductAmount ?? 0)) * (values.tax ? 0.05 : 0)
      ),
      incomeWithTaxEstimated:
        Math.round(totalPower * values.sellPrice) +
        (values.deductAmount ?? 0) +
        Math.round(
          (Math.round(totalPower * values.sellPrice) + (values.deductAmount ?? 0)) * (values.tax ? 0.05 : 0)
        ),
    });
  };

  handlePriceChange = (price) => {
    const values = this.props.form.getFieldsValue(['totalPower', 'deductAmount', 'tax']);

    this.props.form.setFieldsValue({
      powerAmount: Math.round(values.totalPower * price),
      salesTax: Math.round(
        (Math.round(values.totalPower * price) + (values.deductAmount ?? 0)) * (values.tax ? 0.05 : 0)
      ),
      incomeWithTaxEstimated:
        Math.round(values.totalPower * price) +
        (values.deductAmount ?? 0) +
        Math.round(
          (Math.round(values.totalPower * price) + (values.deductAmount ?? 0)) * (values.tax ? 0.05 : 0)
        ),
    });
  };

  handlePowerAmountChange = (amount) => {
    const values = this.props.form.getFieldsValue(['deductAmount', 'tax']);
    this.props.form.setFieldsValue({
      salesTax: Math.round((amount + (values.deductAmount ?? 0)) * (values.tax ? 0.05 : 0)),
      incomeWithTaxEstimated:
        amount +
        (values.deductAmount ?? 0) +
        Math.round((amount + (values.deductAmount ?? 0)) * (values.tax ? 0.05 : 0)),
    });
  };

  handleDeductAmountChange = (amount) => {
    const values = this.props.form.getFieldsValue(['totalPower', 'sellPrice', 'tax']);

    amount = (amount ?? 0) === '' ? 0 : amount ?? 0;
    this.props.form.setFieldsValue({
      salesTax: Math.round(
        (Math.round(values.totalPower * values.sellPrice) + amount) * (values.tax ? 0.05 : 0)
      ),
      incomeWithTaxEstimated:
        Math.round(values.totalPower * values.sellPrice) +
        amount +
        Math.round((Math.round(values.totalPower * values.sellPrice) + amount) * (values.tax ? 0.05 : 0)),
    });
  };

  handleSalesTaxChange = (salestax) => {
    const values = this.props.form.getFieldsValue(['totalPower', 'sellPrice', 'deductAmount']);

    this.props.form.setFieldsValue({
      incomeWithTaxEstimated:
        Math.round(values.totalPower * values.sellPrice) +
        (values.deductAmount !== undefined ? values.deductAmount : 0) +
        salestax,
    });
  };

  handlePowerRentChange = (power) => {
    const values = this.props.form.getFieldsValue(['makeupRent', 'meterRentCost']);

    this.props.form.setFieldsValue({
      meterRentCost: power + (values.makeupRent ?? 0),
    });
  };

  handleMakeupRentChange = (makeup) => {
    const values = this.props.form.getFieldsValue(['powerRent', 'meterRentCost']);

    makeup = (makeup ?? 0) === '' ? 0 : makeup ?? 0;
    this.props.form.setFieldsValue({
      meterRentCost: (values.powerRent ?? 0) + makeup,
    });
  };

  handleTaxChange = (e) => {
    console.log('tax', e.target.value);
    const tax = e.target.value;
    const values = this.props.form.getFieldsValue(['totalPower', 'sellPrice', 'deductAmount']);

    this.props.form.setFieldsValue({
      salesTax: Math.round(
        (Math.round(values.totalPower * values.sellPrice) + (values.deductAmount ?? 0)) * (tax ? 0.05 : 0)
      ),
      incomeWithTaxEstimated:
        Math.round(values.totalPower * values.sellPrice) +
        (values.deductAmount ?? 0) +
        Math.round(
          (Math.round(values.totalPower * values.sellPrice) + (values.deductAmount ?? 0)) * (tax ? 0.05 : 0)
        ),
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
    });
    setTimeout(() => this.props.onHide(), 500);
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onHandle(values);
      }
    });
  };

  checkUpload = (rule, value, callback) => {
    const values = this.props.form.getFieldsValue(['upload']);
    if (values.upload && values.upload.length > 1) {
      callback('僅能上傳一份電費單, 請刪除多餘的');
      return;
    }
    callback();
  };

  checkIncomeWithTax = (rule, value, callback) => {
    if (value) {
      const values = this.props.form.getFieldsValue(['incomeWithTaxEstimated']);

      if (Math.abs(values.incomeWithTaxEstimated - value) > 1) {
        callback('目前金額與預估金額差異太大, 請再次確認');
        return;
      }
    }
    callback();
  };

  handleChange = (info) => {
    if (info.file.status === 'done') {
      const { detect } = info.file.response.result;
      if (detect && detect.income !== 0 && detect.income === detect.logical_income) {
        const values = this.props.form.getFieldsValue(['tax']);
        //有單價的是v1；無單價的是v2
        if (detect.sell_price !== null) {
          this.props.form.setFieldsValue({
            rangeBill: [moment(detect.from), moment(detect.to).subtract(1, 'days')],
            range: [moment(detect.from), moment(detect.to)],
            totalPower: detect.kwt,
            sellPrice: detect.sell_price,
            powerAmount: detect.powerAmount ?? Math.round(detect.kwt * detect.sellPrice),
            deductAmount: detect.deductAmount ?? 0,
            salesTax: detect.salesTax ?? Math.round(Math.round(detect.kwt * detect.sellPrice) * 0.05),
            incomeWithTax: detect.income,
            powerRent: detect.powerRent ?? detect.meter_rent - (detect.makeupRent ?? 0),
            makeupRent: detect.makeupRent ?? 0,
            meterRentCost: detect.meter_rent,
            incomeWithTaxEstimated: Math.round(
              (Math.round(detect.kwt * detect.sellPrice) + (detect.deductAmount ?? 0)) *
                (values.tax ? 1.05 : 1)
            ),
          });
        } else {
          this.props.form.setFieldsValue({
            rangeBill: [moment(detect.from), moment(detect.to)],
            range: [moment(detect.from), moment(detect.to).add(1, 'days')],
            totalPower: detect.kwt,
            sellPrice: this.props.sellPrice,
            powerAmount: detect.powerAmount ?? Math.round(detect.kwt * this.props.sellPrice),
            deductAmount: detect.deductAmount ?? 0,
            salesTax: detect.salesTax ?? Math.round(Math.round(detect.kwt * this.props.sellPrice) * 0.05),
            incomeWithTax: detect.income,
            powerRent: detect.powerRent ?? detect.meter_rent - (detect.makeupRent ?? 0),
            makeupRent: detect.makeupRent ?? 0,
            meterRentCost: detect.meter_rent,
            incomeWithTaxEstimated: Math.round(
              (Math.round(detect.kwt * this.props.sellPrice) + (detect.deductAmount ?? 0)) *
                (values.tax ? 1.05 : 1)
            ),
          });
        }
      } else {
        Modal.warn({
          title: '警示訊息',
          content: '無法自動辨識, 請手動輸入資料',
        });
      }
    }
  };

  normFile = (e) => {
    console.log('Upload event:', e);
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  // 測試自動辨識回傳值連帶功能
  handleTestUpload = () => {
    const kwt = 51018;
    const sellPrice = 4.8111;
    const powerAmount = null;
    const deductAmount = null;
    const salesTax = null;
    const income = 257726;
    const powerRent = null;
    const makeupRent = null;
    const meter_rent = 296;

    const values = this.props.form.getFieldsValue(['tax']);
    this.props.form.setFieldsValue({
      rangeBill: [moment('2021-06-25'), moment('2021-07-26').subtract(1, 'days')],
      range: [moment('2021-06-25'), moment('2021-07-26')],
      totalPower: kwt,
      sellPrice: sellPrice,
      powerAmount: powerAmount ?? Math.round(kwt * sellPrice),
      deductAmount: deductAmount ?? 0,
      salesTax: salesTax ?? Math.round(Math.round(kwt * sellPrice) * 0.05),
      incomeWithTax: income,
      powerRent: powerRent ?? meter_rent - (makeupRent ?? 0),
      makeupRent: makeupRent ?? 0,
      meterRentCost: meter_rent === 0 ? null : meter_rent,
      incomeWithTaxEstimated: Math.round(
        (Math.round(kwt * sellPrice) + (deductAmount ?? 0)) * (values.tax ? 1.05 : 1)
      ),
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    const formItemLayout = {
      labelCol: { span: 9 },
      wrapperCol: { span: 14 },
    };

    const tailFormItemLayout = {
      wrapperCol: {
        span: 8,
        offset: 20,
      },
    };

    return (
      <Modal
        title="創建電費單"
        visible={this.state.visible}
        onCancel={this.handleCancel}
        footer={null}
        width="830px"
      >
        <Form onSubmit={this.handleSubmit}>
          <Row>
            <Col span={12}>
              <Form.Item {...formItemLayout} label="電費單文件">
                {getFieldDecorator('upload', {
                  valuePropName: 'fileList',
                  getValueFromEvent: this.normFile,
                  rules: [
                    {
                      type: 'array',
                      required: true,
                      message: '請上傳電費單',
                    },
                    {
                      validator: this.checkUpload,
                    },
                  ],
                })(
                  <Upload
                    name="file"
                    action="/api/bill/upload"
                    headers={{ authorization: getToken() }}
                    onChange={this.handleChange}
                  >
                    <Button>
                      <Icon type="upload" /> 點擊上傳
                    </Button>
                  </Upload>
                )}
                {/* 測試自動辨識按鈕 */}
                {false && <Button onClick={this.handleTestUpload}>測試自動辨識</Button>}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <FormItem
                {...formItemLayout}
                label={
                  <span>
                    計費期間&nbsp;
                    <Tooltip title="請參考電費單上的計費期間，填入起訖日">
                      <Icon type="question-circle-o" />
                    </Tooltip>
                  </span>
                }
              >
                {getFieldDecorator('rangeBill', {
                  rules: [
                    {
                      type: 'array',
                      required: true,
                      message: '請輸入有效期間',
                    },
                  ],
                })(
                  <RangePicker
                    placeholder={['計費起始日', '計費結束日']}
                    onChange={this.handleRangeBillChange}
                  />
                )}
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem
                {...formItemLayout}
                label={
                  <span>
                    抄表期間&nbsp;
                    <Tooltip title="請參考電費單上本期抄表紀錄，填入本次抄表日&下次抄表日">
                      <Icon type="question-circle-o" />
                    </Tooltip>
                  </span>
                }
              >
                {getFieldDecorator('range', {
                  rules: [
                    {
                      type: 'array',
                      required: true,
                      message: '請輸入有效期間',
                    },
                  ],
                })(<RangePicker placeholder={['本次抄表日', '下次抄表日']} disabled />)}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <FormItem
                {...formItemLayout}
                label={
                  <span>
                    發電度數&nbsp;
                    <Tooltip title="請參考電費單上的計費度數">
                      <Icon type="question-circle-o" />
                    </Tooltip>
                  </span>
                }
              >
                {getFieldDecorator('totalPower', {
                  initialValue: null,
                  rules: [
                    {
                      type: 'number',
                      required: true,
                      message: '請輸入發電度數',
                    },
                  ],
                })(<InputNumber style={{ width: '190px' }} min={0} onChange={this.handlePowerChange} />)}
                <span className="ant-form-text"> 度</span>
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem {...formItemLayout} label={<span>躉售電價</span>}>
                {getFieldDecorator('sellPrice', {
                  initialValue: this.props.sellPrice,
                  rules: [
                    {
                      type: 'number',
                      required: true,
                      message: '請輸入躉售電價',
                    },
                  ],
                })(<InputNumber style={{ width: '190px' }} min={1} onChange={this.handlePriceChange} />)}
                <span className="ant-form-text"> 元</span>
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <FormItem {...formItemLayout} label={<span>發電金額</span>}>
                {getFieldDecorator('powerAmount', {
                  initialValue: this.props.powerAmount,
                  rules: [
                    {
                      type: 'number',
                      required: true,
                      message: '請輸入發電金額',
                    },
                  ],
                })(
                  <InputNumber style={{ width: '190px' }} min={0} onChange={this.handlePowerAmountChange} />
                )}
                <span className="ant-form-text"> 元</span>
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem {...formItemLayout} label={<span>補付(扣)電費</span>}>
                {getFieldDecorator('deductAmount', {
                  initialValue: this.props.deductAmount,
                  rules: [
                    {
                      type: 'number',
                      message: '請輸入補付(扣)電費',
                    },
                  ],
                })(<InputNumber style={{ width: '190px' }} onChange={this.handleDeductAmountChange} />)}
                <span className="ant-form-text"> 元</span>
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <FormItem {...formItemLayout} label={<span>預估電費收入</span>}>
                {getFieldDecorator('incomeWithTaxEstimated', {
                  initialValue: null,
                })(<InputNumber style={{ width: '190px' }} min={0} disabled />)}
                <span className="ant-form-text"> 元</span>
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <FormItem
                {...formItemLayout}
                label={
                  <span>
                    營業稅率&nbsp;
                    <Tooltip title="請參考電費單上是否附加營業稅">
                      <Icon type="question-circle-o" />
                    </Tooltip>
                  </span>
                }
              >
                {getFieldDecorator('tax', {
                  initialValue: true,
                  rules: [
                    {
                      required: true,
                      message: '請輸入含稅總電費',
                    },
                  ],
                })(
                  <Radio.Group onChange={this.handleTaxChange}>
                    <Radio value={true}>5%</Radio>
                    <Radio value={false}>0%</Radio>
                  </Radio.Group>
                )}
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem {...formItemLayout} label={<span>營業稅</span>}>
                {getFieldDecorator('salesTax', {
                  initialValue: this.props.salesTax,
                  rules: [
                    {
                      type: 'number',
                      required: true,
                      message: '請輸入營業稅',
                    },
                  ],
                })(<InputNumber style={{ width: '190px' }} min={0} onChange={this.handleSalesTaxChange} />)}
                <span className="ant-form-text"> 元</span>
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <FormItem
                {...formItemLayout}
                label={
                  <span>
                    電費收入&nbsp;
                    <Tooltip title="請參考電費單上的含稅總電費">
                      <Icon type="question-circle-o" />
                    </Tooltip>
                  </span>
                }
              >
                {getFieldDecorator('incomeWithTax', {
                  initialValue: null,
                  rules: [
                    {
                      type: 'number',
                      required: true,
                      message: '請輸入含稅總電費',
                    },
                    {
                      validator: this.checkIncomeWithTax,
                    },
                  ],
                })(<InputNumber style={{ width: '190px' }} min={0} />)}
                <span className="ant-form-text"> 元</span>
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <FormItem {...formItemLayout} label={<span>本期電表租費</span>}>
                {getFieldDecorator('powerRent', {
                  initialValue: this.props.powerRent,
                  rules: [
                    {
                      type: 'number',
                      required: true,
                      message: '請輸入本期電表租費',
                    },
                  ],
                })(<InputNumber style={{ width: '190px' }} min={0} onChange={this.handlePowerRentChange} />)}
                <span className="ant-form-text"> 元</span>
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem {...formItemLayout} label={<span>電表租費補收(退)</span>}>
                {getFieldDecorator('makeupRent', {
                  initialValue: this.props.makeupRent,
                  rules: [
                    {
                      type: 'number',
                      message: '請輸入電表租費補收(退)',
                    },
                  ],
                })(<InputNumber style={{ width: '190px' }} onChange={this.handleMakeupRentChange} />)}
                <span className="ant-form-text"> 元</span>
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <FormItem
                {...formItemLayout}
                label={
                  <span>
                    電表租費&nbsp;
                    <Tooltip title="請參考電費單上的電表租費">
                      <Icon type="question-circle-o" />
                    </Tooltip>
                  </span>
                }
              >
                {getFieldDecorator('meterRentCost', {
                  initialValue: null,
                  rules: [
                    {
                      type: 'number',
                      required: true,
                      message: '請輸入電表租費',
                    },
                  ],
                })(<InputNumber style={{ width: '190px' }} min={0} />)}
                <span className="ant-form-text"> 元</span>
              </FormItem>
            </Col>
          </Row>

          <FormItem {...tailFormItemLayout}>
            <Button type="primary" htmlType="submit">
              確定
            </Button>
          </FormItem>
        </Form>
      </Modal>
    );
  }
}

const WrappedBillCreateDialogForm = Form.create()(BillCreateDialog);

export default WrappedBillCreateDialogForm;
