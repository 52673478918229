import React from 'react';
import { Col } from 'antd';

const JCol = (props) => {
  return (
    <Col {...props} style={{ textAlign: props.align || 'left' }}>
      {props.children}
    </Col>
  );
};

export default JCol;
