import React from 'react';
import styled from 'styled-components';

const Subtitle = ({ dense = false, children }) => {
  return <Div dense={dense}>{children}</Div>;
};

const Div = styled.div`
  width: 100%;
  border-left: 3px solid #f7b100;
  padding: 5px 10px;
  margin-top: ${(p) => (p.dense ? '0px' : '50px')};
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: bold;
`;

export default Subtitle;
