import React, { Component } from 'react';
import axios from 'axios';
import { Table, Modal } from 'antd';
import { getToken } from '../../utils/setting';

class Info extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataSource: null,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    return axios
      .get(`/api/spv/infoForSola/${this.props.id}`, { headers: { Authorization: getToken() } })
      .then((res) => {
        if (res.data.status) {
          this.setState({ dataSource: res.data.result.info[0] });
        }
      })
      .catch((error) => {
        Modal.error({
          title: '錯誤訊息',
          content: error.response.data.msg || '很抱歉, 系統發生錯誤, 請稍後重試或聯絡系統管理員',
        });
      });
  }

  render() {
    const d = this.state.dataSource;
    if (d === null) {
      return <div>No data</div>;
    }

    const padding = 32;
    const fontSize = 14;
    const columns = [
      {
        title: '項目',
        dataIndex: 'colName',
        width: padding + fontSize * 6,
        key: 'colName',
      },
      {
        title: '資料值',
        dataIndex: 'colValue',
        key: 'colValue',
      },
    ];

    let dataSource = [];
    dataSource.push({
      key: 'name',
      colName: '顯示名稱',
      colValue: d.name,
    });
    dataSource.push({
      key: 'companyName',
      colName: '公司名稱',
      colValue: d.companyName,
    });
    dataSource.push({
      key: 'address',
      colName: '地址',
      colValue: d.address,
    });
    dataSource.push({
      key: 'cashoutAccount',
      colName: '出金帳戶',
      colValue: d.cashoutAccount,
    });

    return (
      <div>
        <Table dataSource={dataSource} columns={columns} pagination={false} />
      </div>
    );
  }
}

export default Info;
