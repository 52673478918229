import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment';
import styled from 'styled-components';
import { DataProvider, LineChart, Series } from '@cognite/griff-react';
import { Layout, Card, Modal, DatePicker, Spin, Button } from 'antd';
import { getToken } from '../utils/setting';
const { Header, Content } = Layout;

const getColor = (cityPrefix) => {
  switch (cityPrefix) {
    case 'A':
    case 'C':
    case 'F':
    case 'G':
      return 'BLACK';
    case 'B':
      return 'INDIANRED';
    case 'D':
      return 'SEAGREEN';
    case 'E':
      return 'DODGERBLUE';
    case 'H':
      return 'PALEVIOLETRED';
    case 'Q':
      return 'LIGHTSEAGREEN';
    case 'I':
      return 'LIGHTSEAGREEN';
    case 'J':
      return 'CORAL';
    case 'O':
      return 'CORAL';
    case 'K':
      return 'LIMEGREEN';
    case 'M':
      return 'ROYALBLUE';
    case 'N':
      return 'DARKKHAKI';
    case 'P':
      return 'MEDIUMPURPLE';
    case 'T':
      return 'ROSYBROWN';
    case 'U':
      return 'STEELBLUE';
    case 'V':
      return 'GOLDENROD';
    case 'W':
      return 'DARKGRAY';
    case 'X':
      return 'PLUM';
    case 'Z':
      return 'DARKSLATEGRAY';
    default:
      return 'BLACK';
  }
};

const myLoader = ({ oldSeries, reason, data }) => {
  return { data, drawPoints: true };
};

class PowerHistory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      date: moment().subtract(1, 'days'),
      pvMap: {},
      minValue: null,
      maxValue: null,
    };
  }

  componentDidMount() {
    this.loadData(this.state.date);
  }

  loadData(newDate) {
    this.setState({ loaded: false, date: newDate });

    axios
      .get(`/api/pv/getPowerList/${newDate.format('YYYY-MM-DD')}`, { headers: { Authorization: getToken() } })
      .then((res) => {
        if (res && res.data && res.data.status) {
          const powerList = res.data.result.powerList || [];
          const pvMap = {};
          let minValue = -0.4;
          let maxValue = 3.5;
          let p = null;
          let timestamp = null;

          for (let i = 0; i < powerList.length; i++) {
            p = powerList[i];
            timestamp = moment(p.date).valueOf();

            if (p.unitValue < minValue) minValue = p.unitValue - 0.1;
            if (p.unitValue > maxValue) maxValue = p.unitValue + 0.1;

            if (!pvMap[p.sntwId]) {
              pvMap[p.sntwId] = {
                projectCode: p.projectCode,
                name: p.name,
                data: [{ timestamp: timestamp, value: p.unitValue }],
              };
            } else {
              pvMap[p.sntwId].data.push({ timestamp: timestamp, value: p.unitValue });
            }
          }

          this.setState({ pvMap, loaded: true, minValue, maxValue });
        }
      })
      .catch((error) => {
        Modal.error({
          title: '錯誤訊息',
          content: error.response.data.msg || '很抱歉, 系統發生錯誤, 請稍後重試或聯絡系統管理員',
        });
      });
  }

  onChange = (date) => {
    this.loadData(date);
  };

  onClickPrevious = () => {
    const { date } = this.state;
    this.loadData(date.subtract(1, 'days'));
  };

  onClickNext = () => {
    const { date } = this.state;
    this.loadData(date.add(1, 'days'));
  };

  render() {
    const staticXDomain = [
      new Date(this.state.date.format('YYYY-MM-DD 01:00:00')).getTime(),
      new Date(this.state.date.format('YYYY-MM-DD 23:59:59')).getTime(),
    ];
    return (
      <div>
        <Header
          style={{
            background: '#fff',
            padding: 0,
            textAlign: 'center',
            fontSize: '2em',
          }}
        >
          歷史線圖
        </Header>
        <Content style={{ margin: '24px 16px 0', overflow: 'initial' }}>
          <Container>
            <Button onClick={this.onClickPrevious} disabled={!this.state.loaded}>
              ＜＜
            </Button>
            <DatePicker
              defaultValue={this.state.date}
              onChange={this.onChange}
              disabled={!this.state.loaded}
            />
            <Button onClick={this.onClickNext} disabled={!this.state.loaded}>
              ＞＞
            </Button>
          </Container>
          <Card style={{ width: '100%' }}>
            <Container>
              {this.state.loaded ? (
                Object.keys(this.state.pvMap)
                  .sort()
                  .map((pv, i) => (
                    <div
                      key={i}
                      style={{
                        width: '25%',
                      }}
                    >
                      <Name>{`${this.state.pvMap[pv].projectCode}${this.state.pvMap[pv].name}`}</Name>
                      <DataProvider
                        defaultLoader={({ oldSeries, reason }) =>
                          myLoader({ oldSeries, reason, data: this.state.pvMap[pv].data })
                        }
                        timeDomain={staticXDomain}
                        pointWidth={3}
                        ySubDomain={[this.state.minValue, this.state.maxValue]}
                      >
                        <Series id="1" color={getColor(pv[0])} />
                        <LineChart
                          contextChart={{
                            visible: false,
                          }}
                          height={150}
                          yAxisTicks={4}
                          ruler={{
                            visible: true,
                            yLabel: (point) => `${Number.parseFloat(point.value).toFixed(3)}度/kW`,
                            timeLabel: (point) => moment(point.timestamp).format('MM/DD HH:mm:ss'),
                          }}
                        />
                      </DataProvider>
                    </div>
                  ))
              ) : (
                <div style={{ width: '100%', textAlign: 'center' }}>
                  <Spin size="large" />
                </div>
              )}
            </Container>
          </Card>
        </Content>
      </div>
    );
  }
}

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Name = styled.div`
  font-size: 10px;
`;

export default PowerHistory;
