import React, { Component } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Icon, Modal, Button, Empty } from 'antd';
import { getToken } from '../../utils/setting';

class PvImage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.changed) {
      this.loadData();
    }
  }

  loadData() {
    return axios
      .get(`/api/image/${this.props.id}`, { headers: { Authorization: getToken() } })
      .then((res) => {
        if (res.data.status) {
          this.setState({ data: res.data.result.pvImage });
        } else {
          Modal.error({
            title: '錯誤訊息',
            content: res.data.msg,
          });
        }
      })
      .catch((error) => {
        Modal.error({
          title: '錯誤訊息',
          content: error.response.data.msg || '很抱歉, 系統發生錯誤, 請稍後重試或聯絡系統管理員',
        });
      });
  }

  handleCheck = () => {
    axios
      .post(
        `/api/image/check`,
        {
          id: this.props.id,
        },
        { headers: { Authorization: getToken() } }
      )
      .then((res) => {
        if (res.data.status) {
          this.loadData();
        } else {
          Modal.error({
            title: '錯誤訊息',
            content: res.data.msg,
          });
        }
      })
      .catch((error) => {
        Modal.error({
          title: '錯誤訊息',
          content: error.response.data.msg || '很抱歉, 系統發生錯誤, 請稍後重試或聯絡系統管理員',
        });
      });
  };

  handleReject = () => {
    axios
      .post(
        `/api/image/reject`,
        {
          id: this.props.id,
        },
        { headers: { Authorization: getToken() } }
      )
      .then((res) => {
        if (res.data.status) {
          this.loadData();
        } else {
          Modal.error({
            title: '錯誤訊息',
            content: res.data.msg,
          });
        }
      })
      .catch((error) => {
        Modal.error({
          title: '錯誤訊息',
          content: error.response.data.msg || '很抱歉, 系統發生錯誤, 請稍後重試或聯絡系統管理員',
        });
      });
  };

  handleSync = () => {
    axios
      .post(
        `/api/image/sync`,
        {
          id: this.props.id,
        },
        { headers: { Authorization: getToken() } }
      )
      .then((res) => {
        if (res.data.status) {
          this.loadData();
        } else {
          Modal.error({
            title: '錯誤訊息',
            content: res.data.msg,
          });
        }
      })
      .catch((error) => {
        Modal.error({
          title: '錯誤訊息',
          content: error.response.data.msg || '很抱歉, 系統發生錯誤, 請稍後重試或聯絡系統管理員',
        });
      });
  };

  render() {
    const { data } = this.state;
    if (data) {
      let operation = null,
        status = null;

      if (this.props.forceRejectMode) {
        if (!(data.updateTime <= data.rejectTime)) {
          operation = (
            <Button onClick={this.handleReject}>
              <Icon type="close" />
              否決
            </Button>
          );
        }
      } else if (!(data.updateTime <= data.checkTime) && !(data.updateTime <= data.rejectTime)) {
        operation = (
          <div>
            <Button onClick={this.handleCheck}>
              <Icon type="check" />
              核准
            </Button>
            <Button onClick={this.handleReject}>
              <Icon type="close" />
              否決
            </Button>
          </div>
        );
      } else {
        if (data.checkTime && !(data.checkTime <= data.rejectTime) && !(data.syncTime >= data.checkTime)) {
          operation = (
            <Button onClick={this.handleSync}>
              <Icon type="sync" />
              同步
            </Button>
          );
        }
      }

      if (data.rejectTime) {
        if (data.checkTime) {
          if (data.rejectTime > data.checkTime && data.rejectTime > data.updateTime) {
            status = <div style={{ color: 'red' }}>否決中</div>;
          }
        } else if (data.rejectTime > data.updateTime) {
          status = <div style={{ color: 'red' }}>否決中</div>;
        }
      }

      const images = data.files.map((image, i) => (
        <Item key={i}>
          <Link to={`/api/upload/file/${image}`} target="_blank">
            <Img src={`/api/upload/file/${image}`} alt="" />
          </Link>
        </Item>
      ));

      return (
        <div>
          {operation}
          {status}
          {images.length > 0 ? (
            <Container>{images}</Container>
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
        </div>
      );
    } else {
      return null;
    }
  }
}

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Item = styled.div`
  position: relative;
  margin-top: 10px;
  margin-right: 10px;
  width: 300px;
  height: 300px;
  border: 1px solid lightgrey;
  padding: 25px 0 15px 0;
`;

const Img = styled.img`
  display: block;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
`;
export default PvImage;
