export const numberFormat = args => {
  if (!args) {
    return 'unknown';
  }

  const { value, prefix, postfix } = args;
  if (value === null || value === undefined) {
    return '';
  }

  let v = Number(value).toLocaleString(undefined, {
    maximumSignificantDigits: 8
  });

  if (prefix) {
    v = `${prefix}${v}`;
  }

  if (postfix) {
    v = `${v} ${postfix}`;
  }
  return v;
};

export const percentFormat = v => {
  if (v === null || v === undefined) {
    return '';
  }

  return Number(v).toLocaleString(undefined, {
    style: 'percent',
    minimumSignificantDigits: 1,
    maximumSignificantDigits: 8
  });
};
