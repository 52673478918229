import React from 'react';
import styled from 'styled-components';
import { Input } from 'antd';
const { Password } = Input;

const JPassword = ({ label, msg, required = false, ...props }) => {
  return (
    <Div>
      <LabelDiv required={required}>{label}</LabelDiv>
      <InputDiv>
        <Password {...props} />
      </InputDiv>
      <MsgDiv>{msg}</MsgDiv>
    </Div>
  );
};

const Div = styled.div`
  width: 100%;
`;
const LabelDiv = styled.div`
  width: 100%;
  font-size: 16px;
  margin-bottom: 5px;

  ${(p) =>
    p.required
      ? `
        font-weight: bold;
        ::after {
            content: " *";
            color: #f00;
        }
        `
      : ''}
`;
const InputDiv = styled.div`
  width: 100%;
`;
const MsgDiv = styled.div`
  width: 100%;
  font-size: 12px;
  color: #f00;
`;

export default JPassword;
