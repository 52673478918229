import React from 'react';
import { Layout, Tabs, Card } from 'antd';
import Info from './Info';
import Holding from './Holding';

const { Header, Content } = Layout;
const TabPane = Tabs.TabPane;

const SpvMaintain3 = props => (
  <div>
    <Header
      style={{
        background: '#fff',
        padding: 0,
        textAlign: 'center',
        fontSize: '2em'
      }}
    >
      SPV資料
    </Header>
    <Content
      style={{
        margin: '24px 16px 0',
        overflow: 'initial',
        width: '100%'
      }}
    >
      <Card style={{ width: '100%' }}>
        <Tabs defaultActiveKey="1">
          <TabPane tab="基本資料" key="1">
            <Info id={props.match.params.spvId} />
          </TabPane>
          <TabPane tab="持有受益權" key="2">
            <Holding id={props.match.params.spvId} />
          </TabPane>
        </Tabs>
      </Card>
    </Content>
  </div>
);

export default SpvMaintain3;
