import React, { Component } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { numberFormat } from '../Utils/Format';
import { Form, DatePicker, Button, Modal, Input, Table } from 'antd';
const FormItem = Form.Item;

class SettlementViewDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: true
    };
  }

  handleCancel = () => {
    this.setState({
      visible: false
    });
    setTimeout(() => this.props.onHide(), 500);
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onHandle(values);
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 14 }
    };

    const tailFormItemLayout = {
      wrapperCol: {
        span: 8,
        offset: 6
      }
    };

    const padding = 32;
    const fontSize = 14;
    const columns = [
      {
        title: '金額',
        dataIndex: 'profit',
        key: 'profit',
        width: padding + fontSize * 6,
        render: v => numberFormat({ value: v, postfix: '元' })
      },
      {
        title: '附註',
        dataIndex: 'reason',
        key: 'reason',
        width: padding + fontSize * 20
      }
    ];

    const totalWidth = columns.reduce((total, elem) => {
      return total + elem.width;
    }, 0);

    return (
      <Modal
        title="結算請款明細"
        visible={this.state.visible}
        onCancel={this.handleCancel}
        footer={null}
        width={1000}
      >
        <Container>
          <div style={{ width: '500px' }}>
            <Form onSubmit={this.handleSubmit}>
              <FormItem {...formItemLayout} label={<span>結算月份</span>}>
                {getFieldDecorator('settlementMonth', { initialValue: this.props.data.settlementMonth })(
                  <Input disabled />
                )}
              </FormItem>
              <FormItem {...formItemLayout} label={<span>營運公司</span>}>
                {getFieldDecorator('owner', { initialValue: this.props.data.owner })(<Input disabled />)}
              </FormItem>
              <FormItem {...formItemLayout} label={<span>受益者</span>}>
                {getFieldDecorator('beneficiary', { initialValue: this.props.data.beneficiary })(
                  <Input disabled />
                )}
              </FormItem>
              <FormItem {...formItemLayout} label={<span>受益者帳戶</span>}>
                {getFieldDecorator('cashoutAccount', { initialValue: this.props.data.cashoutAccount })(
                  <Input disabled />
                )}
              </FormItem>
              <FormItem {...formItemLayout} label={<span>應得收益</span>}>
                {getFieldDecorator('currentProfit', {
                  initialValue: numberFormat({ value: this.props.data.currentProfit, postfix: '元' })
                })(<Input disabled />)}
              </FormItem>
              <FormItem {...formItemLayout} label={<span>上期損益</span>}>
                {getFieldDecorator('lastAccumulation', {
                  initialValue: numberFormat({ value: this.props.data.lastAccumulation, postfix: '元' })
                })(<Input disabled />)}
              </FormItem>
              <FormItem {...formItemLayout} label={<span>其他帳務</span>}>
                {getFieldDecorator('otherProfit', {
                  initialValue: numberFormat({ value: this.props.data.otherProfit, postfix: '元' })
                })(<Input disabled />)}
              </FormItem>
              <FormItem {...formItemLayout} label={<span>應請金額</span>}>
                {getFieldDecorator('profit', {
                  initialValue: numberFormat({ value: this.props.data.profit, postfix: '元' })
                })(<Input disabled />)}
              </FormItem>
              <FormItem {...formItemLayout} label={<span>實際請款日期</span>}>
                {getFieldDecorator('requestDate', {
                  initialValue: this.props.data.requestDate ? moment(this.props.data.requestDate) : null,
                  rules: [
                    {
                      type: 'object',
                      message: '請輸入有效日期'
                    }
                  ]
                })(<DatePicker placeholder="請填入請款日期" />)}
              </FormItem>
              <FormItem {...tailFormItemLayout}>
                <Button type="primary" htmlType="submit">
                  儲存
                </Button>
              </FormItem>
            </Form>
          </div>
          <div>
            <h3>其他帳務明細: </h3>
            <Table
              dataSource={this.props.data.otherProfitList}
              columns={columns}
              scroll={{ x: totalWidth }}
              pagination={false}
            />
          </div>
        </Container>
      </Modal>
    );
  }
}

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const WrappedSettlementViewDialogForm = Form.create()(SettlementViewDialog);

export default WrappedSettlementViewDialogForm;
