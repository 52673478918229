import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment';
import { Table, Button, Icon, Modal } from 'antd';
import EditDialog from '../Utils/EditDialog';

import valueToName from '../Definition/ValueToName';
import stageList from '../Definition/StageList';
import statusList from '../Definition/StatusList';
import withTaxList from '../Definition/WithTaxList';
import withMeterList from '../Definition/WithMeterList';
import decayList from '../Definition/DecayList';
import { numberFormat, percentFormat } from '../Utils/Format';
import { getToken } from '../../utils/setting';

class PvInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      showEditor: false,
      editorRecord: null,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.changed) {
      this.loadData();
    }
  }

  loadData() {
    return axios
      .get(`/api/pv/maintain/${this.props.id}`, { headers: { Authorization: getToken() } })
      .then((res) => {
        if (res.data.status) {
          console.log('pv data', res.data.result.pv);
          this.setState({ data: res.data.result.pv });
        } else {
          Modal.error({
            title: '錯誤訊息',
            content: res.data.msg,
          });
        }
      })
      .catch((error) => {
        Modal.error({
          title: '錯誤訊息',
          content: error.response.data.msg || '很抱歉, 系統發生錯誤, 請稍後重試或聯絡系統管理員',
        });
      });
  }

  handleEdit = (record) => {
    this.setState({
      showEditor: true,
      editorRecord: record,
    });
  };

  handleNewValue = (v) => {
    const { key } = this.state.editorRecord;
    axios
      .post(
        `/api/pv/edit`,
        {
          id: this.props.id,
          uuid: this.state.data['uuid'],
          name: this.state.data['name'],
          field: key,
          value: moment.isMoment(v[key]) ? v[key].format('YYYY-MM-DD') : v[key],
        },
        { headers: { Authorization: getToken() } }
      )
      .then((res) => {
        if (res.data.status) {
          let { data } = this.state;
          if (moment.isMoment(v[key])) {
            data[key] = v[key].format('YYYY-MM-DD');
          } else {
            data[key] = v[key];
          }
          data[`${key}U`] = moment().format('YYYY-MM-DD HH:mm:ss');
          this.setState({ data: data, showEditor: false });
        } else {
          Modal.error({
            title: '錯誤訊息',
            content: res.data.msg,
          });
        }
      })
      .catch((error) => {
        Modal.error({
          title: '錯誤訊息',
          content: error.response.data.msg || '很抱歉, 系統發生錯誤, 請稍後重試或聯絡系統管理員',
        });
      });
  };

  render() {
    const d = this.state.data;
    if (d === null) {
      return <div>No data</div>;
    }

    const padding = 32;
    const fontSize = 14;
    const columns = [
      {
        title: '項目',
        dataIndex: 'colName',
        width: padding + fontSize * 8,
        key: 'colName',
        render(text, record) {
          return {
            props: {
              style: { color: record.realValue === null ? 'red' : null },
            },
            children: <div>{text}</div>,
          };
        },
      },
      {
        title: '資料值',
        dataIndex: 'colValue',
        key: 'colValue',
        width: padding + fontSize * 20,
      },
      {
        title: '審核狀態',
        key: 'checkStatus',
        width: padding + fontSize * 4,
        render: (text, record) => {
          if (record.timeUpdate) {
            if (record.timeReject >= record.timeUpdate) {
              return <div style={{ color: 'red' }}>被否決</div>;
            } else if (!(record.timeCheck >= record.timeUpdate)) {
              return '待核准';
            } else {
              return null;
            }
          }
        },
      },
      {
        title: '操作',
        key: 'action',
        width: padding + fontSize * 2,
        render: (text, record) => {
          if (record.editable) {
            return (
              <Button onClick={() => this.handleEdit(record)}>
                <Icon type="edit" />
                編輯
              </Button>
            );
          } else {
            return null;
          }
        },
      },
      {
        title: '說明',
        dataIndex: 'desc',
        key: 'desc',
        render: (text, record) => {
          if (record.key === 'meterBeginDate') {
            return [
              text,
              <span style={{ color: 'red', marginLeft: '5px' }} key={0}>
                (掛表日有值時，其他項目也必須有值)
              </span>,
            ];
          } else {
            return text;
          }
        },
      },
    ];

    let dataSource = [];
    dataSource.push({
      key: 'name',
      colName: '電廠名稱',
      colValue: d.name,
      realValue: d.name,
      timeUpdate: d.nameU,
      timeCheck: d.nameC,
      timeReject: d.nameR,
      desc: '微電所使用的電廠名稱',
      editable: true,
      required: true,
      type: 'text',
    });
    dataSource.push({
      key: 'stage',
      colName: '建置階段',
      colValue: valueToName(stageList, d.stage),
      realValue: d.stage,
      timeUpdate: d.stageU,
      timeCheck: d.stageC,
      timeReject: d.stageR,
      desc: '請提供案場最新的建置進度',
      editable: true,
      required: true,
      type: 'selection',
      items: stageList,
    });
    dataSource.push({
      key: 'status',
      colName: '狀態',
      colValue: valueToName(statusList, d.status),
      realValue: d.status,
      timeUpdate: d.statusU,
      timeCheck: d.statusC,
      timeReject: d.statusR,
      desc: '請提供案場的最新發電狀況',
      editable: true,
      required: true,
      type: 'selection',
      items: statusList,
    });
    dataSource.push({
      key: 'projectCode',
      colName: '專案代碼',
      colValue: d.projectCode,
      realValue: d.projectCode,
      timeUpdate: d.projectCodeU,
      timeCheck: d.projectCodeC,
      timeReject: d.projectCodeR,
      desc: '微電所使用的專案代碼, ex.T6001',
      editable: true,
      required: true,
      type: 'text',
    });
    dataSource.push({
      key: 'owner',
      colName: '電廠擁有者',
      colValue: d.owner,
      realValue: d.owner,
      timeUpdate: d.ownerU,
      timeCheck: d.ownerC,
      timeReject: d.ownerR,
      desc: '案場的實際持有者, ex.東迪能源股份有限公司',
      editable: true,
      type: 'text',
    });
    dataSource.push({
      key: 'location',
      colName: '電廠地址',
      colValue: d.location,
      realValue: d.location,
      timeUpdate: d.locationU,
      timeCheck: d.locationC,
      timeReject: d.locationR,
      desc: '電廠的設置地址或地號',
      editable: true,
      required: true,
      type: 'text',
    });
    dataSource.push({
      key: 'coordLat',
      colName: 'GPS緯度',
      colValue: d.coordLat,
      realValue: d.coordLat,
      timeUpdate: d.coordLatU,
      timeCheck: d.coordLatC,
      timeReject: d.coordLatR,
      desc: 'ex. 23.702838',
      editable: true,
      required: true,
      type: 'number',
    });
    dataSource.push({
      key: 'coordLng',
      colName: 'GPS經度',
      colValue: d.coordLng,
      realValue: d.coordLng,
      timeUpdate: d.coordLngU,
      timeCheck: d.coordLngC,
      timeReject: d.coordLngR,
      desc: 'ex. 120.274352',
      editable: true,
      required: true,
      type: 'number',
    });
    dataSource.push({
      key: 'capacity',
      colName: '裝置容量',
      colValue: numberFormat({ value: d.capacity, postfix: 'kWp' }),
      realValue: d.capacity,
      timeUpdate: d.capacityU,
      timeCheck: d.capacityC,
      timeReject: d.capacityR,
      desc: '請提供案場同意備案函核定的總容量',
      editable: true,
      required: true,
      type: 'number',
    });
    dataSource.push({
      key: 'decay',
      colName: '發電年衰減率',
      colValue: valueToName(decayList, d.decay),
      realValue: d.decay,
      timeUpdate: d.decayU,
      timeCheck: d.decayC,
      timeReject: d.decayR,
      editable: true,
      required: true,
      type: 'selection',
      items: decayList,
    });
    dataSource.push({
      key: 'annualAmount',
      colName: '保證發電量',
      colValue: numberFormat({ value: d.annualAmount, postfix: 'kWp' }),
      realValue: d.annualAmount,
      timeUpdate: d.annualAmountU,
      timeCheck: d.annualAmountC,
      timeReject: d.annualAmountR,
      desc: '案場首年每KW最低保證發電量, ex. 1300kWp',
      editable: true,
      required: true,
      type: 'number',
    });
    dataSource.push({
      key: 'constructionCost',
      colName: '單位成本',
      colValue: numberFormat({ value: d.constructionCost, postfix: '元' }),
      realValue: d.constructionCost,
      timeUpdate: d.constructionCostU,
      timeCheck: d.constructionCostC,
      timeReject: d.constructionCostR,
      desc: '案場的每KW建置成本(未稅), ps.同意備案後, 如有調降, 也請更新',
      editable: true,
      required: true,
      type: 'number',
    });
    dataSource.push({
      key: 'totalCapital',
      colName: '總成本',
      colValue: numberFormat({ value: d.totalCapital, postfix: '元' }),
      realValue: d.totalCapital,
      timeUpdate: d.totalCapitalU,
      timeCheck: d.totalCapitalC,
      timeReject: d.totalCapitalR,
      desc: '案場的系統總建置金額(含稅) = 裝置容量 x 單位成本 x 1.05',
      editable: true,
      required: true,
      type: 'number',
    });
    dataSource.push({
      key: 'ownedCapital',
      colName: '理論投資金額',
      colValue: numberFormat({ value: d.ownedCapital, postfix: '元' }),
      realValue: d.ownedCapital,
      timeUpdate: d.ownedCapitalU,
      timeCheck: d.ownedCapitalC,
      timeReject: d.ownedCapitalR,
      desc: '案場投資人的總投資金額 = 總成本 x (1-貸款成數) + 裝置容量 x 1000元[共同開發費]',
      editable: true,
      required: true,
      type: 'number',
    });
    dataSource.push({
      key: 'validityDateBegin',
      colName: '結算起始日',
      colValue: d.validityDateBegin,
      realValue: d.validityDateBegin,
      timeUpdate: d.validityDateBeginU,
      timeCheck: d.validityDateBeginC,
      timeReject: d.validityDateBeginR,
      desc: '電費計算開始日期',
      editable: true,
      type: 'date',
    });
    dataSource.push({
      key: 'validityDuration',
      colName: '結算年限',
      colValue: numberFormat({ value: d.validityDuration, postfix: '年' }),
      realValue: d.validityDuration,
      timeUpdate: d.validityDurationU,
      timeCheck: d.validityDurationC,
      timeReject: d.validityDurationR,
      desc: '購入此電廠時，台電購售電合約的殘餘售電年限, ex.20年',
      editable: true,
      type: 'number',
    });
    dataSource.push({
      key: 'meterBeginDate',
      colName: '掛表日',
      colValue: d.meterBeginDate,
      realValue: d.meterBeginDate,
      timeUpdate: d.meterBeginDateU,
      timeCheck: d.meterBeginDateC,
      timeReject: d.meterBeginDateR,
      desc: '併聯試運轉掛表日',
      editable: true,
      type: 'date',
    });
    dataSource.push({
      key: 'sellPrice',
      colName: '躉售電價',
      colValue: numberFormat({ value: d.sellPrice, postfix: '元' }),
      realValue: d.sellPrice,
      timeUpdate: d.sellPriceU,
      timeCheck: d.sellPriceC,
      timeReject: d.sellPriceR,
      desc: '台電購售電合約的躉售電價',
      editable: true,
      type: 'number',
    });
    dataSource.push({
      key: 'operationRateByCapital',
      colName: '營運固定費率',
      colValue: percentFormat(d.operationRateByCapital),
      realValue: d.operationRateByCapital,
      timeUpdate: d.operationRateByCapitalU,
      timeCheck: d.operationRateByCapitalC,
      timeReject: d.operationRateByCapitalR,
      desc: '依照 每年營運費用/系統總建置金額 所推算出的固定營運費率, ex. 1.2% (如採用浮動費率請填0)',
      editable: true,
      type: 'number',
    });
    dataSource.push({
      key: 'operationRateByIncome',
      colName: '營運浮動費率',
      colValue: percentFormat(d.operationRateByIncome),
      realValue: d.operationRateByIncome,
      timeUpdate: d.operationRateByIncomeU,
      timeCheck: d.operationRateByIncomeC,
      timeReject: d.operationRateByIncomeR,
      desc: '以電費抽成作為營運費用的費率, ex. 10% (如採用固定費率請填0)',
      editable: true,
      type: 'number',
    });
    dataSource.push({
      key: 'serviceRateByIncome',
      colName: '索拉服務費率',
      colValue: percentFormat(d.serviceRateByIncome),
      realValue: d.serviceRateByIncome,
      timeUpdate: d.serviceRateByIncomeU,
      timeCheck: d.serviceRateByIncomeC,
      timeReject: d.serviceRateByIncomeR,
      desc: '以電費抽成作為索拉服務費的費率, ex. 3.5%',
      editable: true,
      type: 'number',
    });
    dataSource.push({
      key: 'serviceBeginDate',
      colName: '索拉服務起始日',
      colValue: d.serviceBeginDate ? d.serviceBeginDate : '結算起始日',
      realValue: d.serviceBeginDate,
      timeUpdate: d.serviceBeginDateU,
      timeCheck: d.serviceBeginDateC,
      timeReject: d.serviceBeginDateR,
      desc: '索拉服務起始日, 若不填, 表示從結算起始日起算',
      editable: true,
      type: 'date',
    });
    dataSource.push({
      key: 'rentYearCost',
      colName: '租金固定費用(年)',
      colValue: numberFormat({ value: d.rentYearCost, postfix: '元' }),
      realValue: d.rentYearCost,
      timeUpdate: d.rentYearCostU,
      timeCheck: d.rentYearCostC,
      timeReject: d.rentYearCostR,
      desc: '依照租約每年需付款的總金額為準, ex. 租金10,000元需含稅請填10,500 (如採用浮動費率請填0)',
      editable: true,
      type: 'number',
    });
    dataSource.push({
      key: 'rentRateByIncome',
      colName: '租金浮動費率',
      colValue: percentFormat(d.rentRateByIncome),
      realValue: d.rentRateByIncome,
      timeUpdate: d.rentRateByIncomeU,
      timeCheck: d.rentRateByIncomeC,
      timeReject: d.rentRateByIncomeR,
      desc: '以電費抽成作為租金的費率, ex. 8% (如採用固定費用請填0)',
      editable: true,
      type: 'number',
    });
    dataSource.push({
      key: 'rentWithTax',
      colName: '浮動租金計費基礎',
      colValue: valueToName(withTaxList, d.rentWithTax),
      realValue: d.rentWithTax,
      timeUpdate: d.rentWithTaxU,
      timeCheck: d.rentWithTaxC,
      timeReject: d.rentWithTaxR,
      desc: '請依照實際租約或實際支付方式選定公式, (如採用固定費用, 此項目無作用)',
      editable: true,
      type: 'selection',
      items: withTaxList,
    });
    dataSource.push({
      key: 'rentWithMeter',
      colName: '浮動租金計費負擔',
      colValue: valueToName(withMeterList, d.rentWithMeter),
      realValue: d.rentWithMeter,
      timeUpdate: d.rentWithMeterU,
      timeCheck: d.rentWithMeterC,
      timeReject: d.rentWithMeterR,
      desc: '請依照實際租約或實際支付方式選定公式, (如採用固定費用, 此項目無作用)',
      editable: true,
      type: 'selection',
      items: withMeterList,
    });
    dataSource.push({
      key: 'sntwId',
      colName: '索拉代碼',
      colValue: d.sntwId,
      realValue: d.sntwId,
      type: 'text',
    });
    dataSource.push({
      key: 'solaName',
      colName: '索拉名稱',
      colValue: d.solaName,
      realValue: d.solaName,
      type: 'text',
    });

    return (
      <div>
        <Table dataSource={dataSource} columns={columns} pagination={false} />
        {this.state.showEditor ? (
          <EditDialog
            record={this.state.editorRecord}
            onNewValue={this.handleNewValue}
            onHide={() => this.setState({ showEditor: false })}
          />
        ) : null}
      </div>
    );
  }
}

export default PvInfo;
