import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Table, Button, Icon, Modal, Upload, DatePicker } from 'antd';
import ConfirmDialog from '../Utils/ConfirmDialog';
import fileItemList from '../Definition/FileItemList';
import { getToken } from '../../utils/setting';

class PvFile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      showDeleteFile: false,
      item: null,
      file: null,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.changed) {
      this.loadData();
    }
  }

  loadData() {
    return axios
      .get(`/api/file/${this.props.id}`, { headers: { Authorization: getToken() } })
      .then((res) => {
        if (res.data.status) {
          this.setState({ data: res.data.result.pvFile });
        } else {
          Modal.error({
            title: '錯誤訊息',
            content: res.data.msg,
          });
        }
      })
      .catch((error) => {
        Modal.error({
          title: '錯誤訊息',
          content: error.response.data.msg || '很抱歉, 系統發生錯誤, 請稍後重試或聯絡系統管理員',
        });
      });
  }

  handleDateChange = (item, date) => {
    axios
      .post(
        `/api/file/modifyGetTime`,
        {
          id: this.props.id,
          item: item,
          date: date ? date.format('YYYY-MM-DD') : null,
        },
        { headers: { Authorization: getToken() } }
      )
      .then((res) => {
        if (res.data.status) {
          this.loadData();
        } else {
          Modal.error({
            title: '錯誤訊息',
            content: res.data.msg,
          });
        }
      })
      .catch((error) => {
        Modal.error({
          title: '錯誤訊息',
          content: error.response.data.msg || '很抱歉, 系統發生錯誤, 請稍後重試或聯絡系統管理員',
        });
      });
  };

  handleUploadChange = (info, item) => {
    if (info.file.status === 'done') {
      if (info.file.response.result.file) {
        axios
          .post(
            `/api/file/uploadFile`,
            {
              id: this.props.id,
              item: item,
              file: info.file.response.result.file,
            },
            { headers: { Authorization: getToken() } }
          )
          .then((res) => {
            if (res.data.status) {
              this.loadData();
            } else {
              Modal.error({
                title: '錯誤訊息',
                content: res.data.msg,
              });
            }
          })
          .catch((error) => {
            Modal.error({
              title: '錯誤訊息',
              content: error.response.data.msg || '很抱歉, 系統發生錯誤, 請稍後重試或聯絡系統管理員',
            });
          });
      } else {
        Modal.error({
          title: '錯誤訊息',
          content: '很抱歉, 系統發生錯誤, 請稍後重試或聯絡系統管理員',
        });
      }
    } else if (info.file.status === 'error') {
      Modal.error({
        title: '錯誤訊息',
        content: '上傳失敗, 請稍後重試或聯絡系統管理員',
      });
    }
  };

  handleOpenDeleteFile = (item, file) => {
    this.setState({
      showDeleteFile: true,
      item,
      file,
    });
  };

  handleDeleteFile = () => {
    axios
      .post(
        `/api/file/deleteFile`,
        {
          id: this.props.id,
          item: this.state.item,
          file: this.state.file,
        },
        { headers: { Authorization: getToken() } }
      )
      .then((res) => {
        if (res.data.status) {
          this.setState({ showDeleteFile: false, item: null, file: null });
          this.loadData();
        } else {
          Modal.error({
            title: '錯誤訊息',
            content: res.data.msg,
          });
        }
      })
      .catch((error) => {
        Modal.error({
          title: '錯誤訊息',
          content: error.response.data.msg || '很抱歉, 系統發生錯誤, 請稍後重試或聯絡系統管理員',
        });
      });
  };

  render() {
    const d = this.state.data;
    if (d === null) {
      return <div>No data</div>;
    }

    const padding = 32;
    const fontSize = 14;
    const columns = [
      {
        title: '項目',
        dataIndex: 'itemName',
        width: padding + fontSize * 10,
        key: 'itemName',
        render(v, record) {
          return {
            props: {
              style: {
                color:
                  (record.needGetTime && !record.getTime) || !record.files || record.files.length === 0
                    ? 'red'
                    : null,
              },
            },
            children: <div>{v}</div>,
          };
        },
      },
      {
        title: '取得時間',
        dataIndex: 'getTime',
        width: padding + fontSize * 8,
        key: 'getTime',
        render: (v, record) =>
          record.needGetTime ? (
            <DatePicker
              placeholder="請選擇日期"
              defaultValue={v ? moment(v) : null}
              onChange={(date) => this.handleDateChange(record.key, date)}
            />
          ) : null,
      },
      {
        title: '檔案',
        dataIndex: 'files',
        key: 'files',
        width: padding + fontSize * 16,
        align: 'left',
        render: (list, record) => {
          if (list) {
            return (
              <Container>
                {list.map((file, i) => (
                  <Item key={i}>
                    <Link to={`/api/upload/file/${file}`} target="_blank">
                      {`查看${i + 1}`}
                    </Link>
                    <Del onClick={() => this.handleOpenDeleteFile(record.key, file)}>
                      <Icon type="close-circle" />
                    </Del>
                  </Item>
                ))}
              </Container>
            );
          } else {
            return null;
          }
        },
      },
      {
        title: '審核狀態',
        key: 'checkStatus',
        width: padding + fontSize * 4,
        render: (text, record) => {
          if (record.timeUpdate) {
            if (record.timeReject >= record.timeUpdate) {
              return <div style={{ color: 'red' }}>被否決</div>;
            } else if (!(record.timeCheck >= record.timeUpdate)) {
              return '待核准';
            } else {
              return null;
            }
          }
        },
      },
      {
        title: '操作',
        key: 'action',
        width: padding + fontSize * 2,
        render: (_, record) => {
          return (
            <Upload
              name="file"
              showUploadList={false}
              action="/api/upload"
              headers={{ authorization: getToken() }}
              onChange={(info) => this.handleUploadChange(info, record.key)}
            >
              <Button>
                <Icon type="upload" /> 上傳
              </Button>
            </Upload>
          );
        },
      },
    ];

    const dataSource = fileItemList.map((v) => ({
      key: v.name,
      itemName: v.name,
      getTime: d.items[v.name] ? d.items[v.name].getTime : null,
      files: d.items[v.name] ? d.items[v.name].files : null,
      needGetTime: v.needGetTime,
      timeUpdate: d.items[v.name] ? d.items[v.name].updateTime : null,
      timeCheck: d.items[v.name] ? d.items[v.name].checkTime : null,
      timeReject: d.items[v.name] ? d.items[v.name].rejectTime : null,
    }));

    return (
      <div>
        <Table dataSource={dataSource} columns={columns} pagination={false} />
        {this.state.showDeleteFile ? (
          <ConfirmDialog
            title={'刪除'}
            msg={`確認刪除 ${this.state.item} 的檔案`}
            onConfirm={this.handleDeleteFile}
            onHide={() => this.setState({ showDeleteFile: false, record: null })}
          />
        ) : null}
      </div>
    );
  }
}

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Item = styled.div`
  position: relative;
  margin-right: 1em;
  width: 4em;
`;

const Del = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 100;
  color: red;
  cursor: pointer;
`;

export default PvFile;
