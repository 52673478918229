export const numberSort = (a, b) => {
  if (a === null) {
    return -1;
  } else if (b === null) {
    return 1;
  } else {
    return a - b;
  }
};

export const stringSort = (a, b) => {
  if (a === null) {
    return -1;
  } else if (b === null) {
    return 1;
  } else {
    return a.localeCompare(b);
  }
};
