import React from 'react';
import { Modal } from 'antd';

const JModal = (props) => {
  return (
    <Modal
      footer={false}
      closable={false}
      style={{ top: '20px' }}
      {...props}
      width={props.visible ? props.width : '0px'}
    >
      {props.visible ? props.children : null}
    </Modal>
  );
};

export default JModal;
