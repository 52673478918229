import React, { Component } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Table, Button, Icon, Modal } from 'antd';
import fileItemList from '../Definition/FileItemList';
import { getToken } from '../../utils/setting';

class PvFile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      showDeleteFile: false,
      item: null,
      file: null,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.changed) {
      this.loadData();
    }
  }

  loadData() {
    return axios
      .get(`/api/file/${this.props.id}`, { headers: { Authorization: getToken() } })
      .then((res) => {
        if (res.data.status) {
          this.setState({ data: res.data.result.pvFile });
        } else {
          Modal.error({
            title: '錯誤訊息',
            content: res.data.msg,
          });
        }
      })
      .catch((error) => {
        Modal.error({
          title: '錯誤訊息',
          content: error.response.data.msg || '很抱歉, 系統發生錯誤, 請稍後重試或聯絡系統管理員',
        });
      });
  }

  handleCheck = (item) => {
    axios
      .post(
        `/api/file/check`,
        {
          id: this.props.id,
          item,
        },
        { headers: { Authorization: getToken() } }
      )
      .then((res) => {
        if (res.data.status) {
          this.loadData();
        } else {
          Modal.error({
            title: '錯誤訊息',
            content: res.data.msg,
          });
        }
      })
      .catch((error) => {
        Modal.error({
          title: '錯誤訊息',
          content: error.response.data.msg || '很抱歉, 系統發生錯誤, 請稍後重試或聯絡系統管理員',
        });
      });
  };

  handleReject = (item) => {
    axios
      .post(
        `/api/file/reject`,
        {
          id: this.props.id,
          item,
        },
        { headers: { Authorization: getToken() } }
      )
      .then((res) => {
        if (res.data.status) {
          this.loadData();
        } else {
          Modal.error({
            title: '錯誤訊息',
            content: res.data.msg,
          });
        }
      })
      .catch((error) => {
        Modal.error({
          title: '錯誤訊息',
          content: error.response.data.msg || '很抱歉, 系統發生錯誤, 請稍後重試或聯絡系統管理員',
        });
      });
  };

  handleSync = (item) => {
    axios
      .post(
        `/api/file/sync`,
        {
          id: this.props.id,
          item,
        },
        { headers: { Authorization: getToken() } }
      )
      .then((res) => {
        if (res.data.status) {
          this.loadData();
        } else {
          Modal.error({
            title: '錯誤訊息',
            content: res.data.msg,
          });
        }
      })
      .catch((error) => {
        Modal.error({
          title: '錯誤訊息',
          content: error.response.data.msg || '很抱歉, 系統發生錯誤, 請稍後重試或聯絡系統管理員',
        });
      });
  };

  render() {
    const d = this.state.data;
    if (d === null) {
      return <div>No data</div>;
    }

    const padding = 32;
    const fontSize = 14;
    const columns = [
      {
        title: '項目',
        dataIndex: 'itemName',
        width: padding + fontSize * 10,
        key: 'itemName',
      },
      {
        title: '取得時間',
        dataIndex: 'getTime',
        width: padding + fontSize * 8,
        key: 'getTime',
      },
      {
        title: '檔案',
        dataIndex: 'files',
        key: 'files',
        width: padding + fontSize * 16,
        align: 'left',
        render: (list, record) => {
          if (list) {
            return (
              <Container>
                {list.map((file, i) => (
                  <Item key={i}>
                    <Link to={`/api/upload/file/${file}`} target="_blank">
                      {`查看${i + 1}  `}
                    </Link>
                  </Item>
                ))}
              </Container>
            );
          } else {
            return null;
          }
        },
      },
      {
        title: '審核狀態',
        key: 'checkStatus',
        width: padding + fontSize * 4,
        render: (text, record) => {
          if (record.timeReject) {
            if (record.timeCheck) {
              if (record.timeReject > record.timeCheck && record.timeReject > record.timeUpdate) {
                return <div style={{ color: 'red' }}>否決中</div>;
              }
            } else if (record.timeReject > record.timeUpdate) {
              return <div style={{ color: 'red' }}>否決中</div>;
            }
          }

          return null;
        },
      },
      {
        title: '操作',
        key: 'action',
        width: padding + fontSize * 6,
        align: 'center',
        render: (_, record) => {
          if (record.timeUpdate) {
            if (this.props.forceRejectMode) {
              if (!(record.timeUpdate <= record.timeReject)) {
                return (
                  <Button onClick={() => this.handleReject(record.key)}>
                    <Icon type="close" />
                    否決
                  </Button>
                );
              } else {
                return null;
              }
            } else if (
              !(record.timeUpdate <= record.timeCheck) &&
              !(record.timeUpdate <= record.timeReject)
            ) {
              return (
                <div>
                  <Button onClick={() => this.handleCheck(record.key)}>
                    <Icon type="check" />
                    核准
                  </Button>
                  <Button onClick={() => this.handleReject(record.key)}>
                    <Icon type="close" />
                    否決
                  </Button>
                </div>
              );
            } else if (
              record.timeCheck &&
              !(record.timeCheck <= record.timeReject) &&
              !(record.timeSync >= record.timeCheck)
            ) {
              return (
                <Button onClick={() => this.handleSync(record.key)}>
                  <Icon type="sync" />
                  同步
                </Button>
              );
            } else {
              return null;
            }
          }
        },
      },
    ];

    const dataSource = fileItemList.map((v) => ({
      key: v.name,
      itemName: v.name,
      getTime: d.items[v.name] ? d.items[v.name].getTime : null,
      files: d.items[v.name] ? d.items[v.name].files : null,
      timeUpdate: d.items[v.name] ? d.items[v.name].updateTime : null,
      timeCheck: d.items[v.name] ? d.items[v.name].checkTime : null,
      timeSync: d.items[v.name] ? d.items[v.name].syncTime : null,
      timeReject: d.items[v.name] ? d.items[v.name].rejectTime : null,
    }));

    return (
      <div>
        <Table dataSource={dataSource} columns={columns} pagination={false} />
      </div>
    );
  }
}

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Item = styled.div`
  position: relative;
  margin-right: 1em;
  width: 4em;
`;

export default PvFile;
