import React, { Component } from 'react';
import { Layout, Tabs, Card } from 'antd';
import PvInfo from './PvInfo';
import PvFile from './PvFile';
import PvImage from './PvImage';
import PvInsurance from './PvInsurance';
import PvLoan from './PvLoan';
import PvLoanFee from './PvLoanFee';
import PvBill from './PvBill';
const { Header, Content } = Layout;
const TabPane = Tabs.TabPane;

class PvMaintain1 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeKey: '1'
    };
  }

  onChange = activeKey => {
    this.setState({ activeKey });
  };

  render() {
    const { activeKey } = this.state;
    return (
      <div>
        <Header
          style={{
            background: '#fff',
            padding: 0,
            textAlign: 'center',
            fontSize: '2em'
          }}
        >
          維護電廠資料
        </Header>
        <Content
          style={{
            margin: '24px 16px 0',
            overflow: 'initial',
            width: '100%'
          }}
        >
          <Card style={{ width: '100%' }}>
            <Tabs defaultActiveKey="1" onChange={this.onChange}>
              <TabPane tab="基本資料" key="1">
                <PvInfo id={this.props.match.params.pvId} changed={activeKey === '1'} />
              </TabPane>
              <TabPane tab="文檔資料" key="2">
                <PvFile id={this.props.match.params.pvId} changed={activeKey === '2'} />
              </TabPane>
              <TabPane tab="實景照片" key="3">
                <PvImage id={this.props.match.params.pvId} changed={activeKey === '3'} />
              </TabPane>
              <TabPane tab="保險資料" key="4">
                <PvInsurance id={this.props.match.params.pvId} changed={activeKey === '4'} />
              </TabPane>
              <TabPane tab="貸款資料" key="5">
                <PvLoan id={this.props.match.params.pvId} changed={activeKey === '5'} />
              </TabPane>
              <TabPane tab="貸款手續費資料" key="6">
                <PvLoanFee id={this.props.match.params.pvId} changed={activeKey === '6'} />
              </TabPane>
              <TabPane tab="電費單資料" key="7">
                <PvBill id={this.props.match.params.pvId} changed={activeKey === '7'} />
              </TabPane>
            </Tabs>
          </Card>
        </Content>
      </div>
    );
  }
}

export default PvMaintain1;
